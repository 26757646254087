import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as PaymentStore from '../../store/Payment';
import PaymentSidebar from './PaymentSidebar';
import T from 'i18n-react';
import validator from 'validator';
import InputMask from 'react-input-mask';
import LoadingButton from '../shared/LoadingButton';
import MessageError from '../shared/MessageError';
import PaymentMethodCollapse from './PaymentMethodCollapse';
import LoadingOverlay from '../shared/LoadingOverlay';
import ReactPlaid from "react-plaid";

const Scroll = require('react-scroll');
const routing = require('../../images/routing.jpg');
const paymentIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/payment-icon.png`);

const initialState = {
    collapse: false,
    paymentAccountTypeID: '3',
    cardAccountName: '',
    cardNumberValue: '',
    expiration: '',
    ccv: '',
    cardNameOnAccount: '',
    bankAccountName: '',
    bankAccountNumber: '',
    bankRoutingNumber: '',
    bankRoutingNumberConfirm: '',
    bankNameOnAccount: '',
    bankAccountType: '0',
    reactPlaidOpen: false,
    account_id: '',
    fields: [
        { name: 'cardAccountName', isRequired: true, paymentAccountTypeID: '3', paymentStripeRequired: 'always' },
        { name: 'cardNumberValue', isRequired: true, paymentAccountTypeID: '3', paymentStripeRequired: 'always', onValidating: CommonStore.isValidCreditCardNumber },
        { name: 'expiration', isRequired: true, paymentAccountTypeID: '3', paymentStripeRequired: 'always', onValidating: CommonStore.isValidCreditCardExpDate },
        { name: 'ccv', isRequired: true, paymentAccountTypeID: '3', paymentStripeRequired: 'always', onValidating: CommonStore.isValidCvv },
        { name: 'cardNameOnAccount', isRequired: true, paymentAccountTypeID: '3', paymentStripeRequired: 'always', minTextWords: 2, onValidating: CommonStore.hasMinWordsAmount },
        { name: 'postalCode', isRequired: true, paymentAccountTypeID: '3', paymentStripeRequired: 'always', onValidating: CommonStore.isValidZipCode}, //5
        { name: 'bankAccountName', isRequired: true, paymentAccountTypeID: '4', paymentStripeRequired: 'always' },
        { name: 'bankAccountNumber', isRequired: true, paymentAccountTypeID: '4', paymentStripeRequired: false },
        { name: 'bankRoutingNumber', isRequired: true, paymentAccountTypeID: '4', paymentStripeRequired: false },
        { name: 'bankRoutingNumberConfirm', isRequired: true, paymentAccountTypeID: '4', isCompare: true, compareField: 'bankRoutingNumber', paymentStripeRequired: false }, //9
        { name: 'account_id', isRequired: true, paymentAccountTypeID: '4', paymentStripeRequired: true },
        { name: 'address1', isRequired: true, paymentAccountTypeID: '3', paymentStripeRequired: 'always' }, //11
        { name: 'city', isRequired: true, paymentAccountTypeID: '3', paymentStripeRequired: 'always' },
        { name: 'bankNameOnAccount', isRequired: true, paymentAccountTypeID: '4', paymentStripeRequired: false, minTextWords: 2, onValidating: CommonStore.hasMinWordsAmount }
    ],
    //CV ZD14560 2020/03/26 adding this properties to handle client stripe token generation
    cardZipCodeStripe: '',
    paymentStripePK: '',
    stripe: {},
    elements: {},
    cardNumberElement: {},
    cardExpiryElement: {},
    cardCvcElement: {},
    slimCD: {}
};

class PaymentMethod extends Component {

    constructor(props) {
        super(props);

        this.state = initialState;

        var paymentStripePK = this.props.getPaymentStripePK();

        this.state.paymentStripePK = this.props.getPaymentStripePK();

        let customer = props.account.customer;

        if (customer) {
            let state = this.state;
            this.state = { ...state, ...customer };
        }

        //CV ZD14560 2020/03/26 adding this script to load the stripe main scripts
        if (paymentStripePK) {
            const script = document.createElement("script");
            script.src = "https://js.stripe.com/v3/";
            script.async = true;
            document.body.appendChild(script);
        }

        if (this.props.systemProperties.isSlimCDEnabled) {
            const scriptSlimCD = document.createElement("script");
            scriptSlimCD.src = "https://stats.slimcd.com/soft/json/slimcd.js";
            scriptSlimCD.async = true;
            document.body.appendChild(scriptSlimCD);
        }
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('paymentMethod.title');

        if (this.props.account.accountNumber) {
            this.props.getPaymentAccounts();
            this.props.requestStatesList();

            let customer = this.props.account.customer;
            this.setState({ ...customer });
        }

        this.props.getI4GoAuthorizeClient();
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getPaymentAccounts();
            this.props.requestStatesList();

            let customer = nextProps.account.customer;
            this.setState({ ...customer });
        }
    }

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    }

    toggleAddPaymentMethod = () => {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        this.props.toggleAddPaymentMethod();
        this.setState({ ...initialState });
        //CV ZD14560 2020/03/26 adding this to initialize the stripe components when select "add new"
        setTimeout(() => {
            if (this.state.paymentAccountTypeID !== '3') return;
            if (!this.props.getPaymentStripePK()) return;            
            this.setState({ stripe: window.Stripe(this.props.getPaymentStripePK()) });
            this.setState({ elements: this.state.stripe.elements() });
            //Create cardnumber element (this is the main element)
            this.setState({ cardNumberElement: this.state.elements.create('cardNumber', { placeholder: ' ' }) });
            this.state.cardNumberElement.mount('#txtCreditCardStripe');
            //Create cardexpiry element        
            this.setState({ cardExpiryElement: this.state.elements.create('cardExpiry', { placeholder: ' ' }) });
            this.state.cardExpiryElement.mount('#txtExpireStripe');
            //Create cardcvc element        
            this.setState({ cardCvcElement: this.state.elements.create('cardCvc', { placeholder: ' ' }) });
            this.state.cardCvcElement.mount('#txtCcvStripe');
        }, 500);

        if (this.props.systemProperties.isSlimCDEnabled) {
            setTimeout(() => {
                this.setState({ slimCD: window["SlimCD"] });
            }, 500);
        }
    }

    onChange = (e) => {
           
        let state = this.state;

        let fields = state.fields.filter(x => x.name === e.target.name && x.paymentAccountTypeID === state.paymentAccountTypeID);

        if (fields && fields.length === 1) {

            let field = fields[0];
            var idx = this.state.fields.indexOf(field);

            if (field.isRequired && field.isRequiredError && e.target.value) {
                field.isRequiredError = false;
            }

            if (field.isNumeric && e.target.value && validator.isDecimal(e.target.value)) {
                field.isNumericError = false;
            }

            if (!field.isRequiredError && field.isCompareError && this.state[field.compareField] === e.target.value) {
                field.isCompareError = false;
            }

            if (field.onValidating) {
                field.onValidating(field, e, this.props);
            }

            fields = this.state.fields;
            fields[idx] = field;

            this.setState({ fields });
        }

        this.setState({ [e.target.name]: e.target.value });

        //CV ZD14560 2020/03/26 adding this script reinitialize the stripe components when the state changes
        setTimeout(() => {
            if (this.state.paymentAccountTypeID !== '3') return;
            if (!this.props.getPaymentStripePK()) return;
            this.setState({ stripe: window.Stripe(this.props.getPaymentStripePK()) });
            this.setState({ elements: this.state.stripe.elements() });
            //Create cardnumber element (this is the main element)
            this.setState({ cardNumberElement: this.state.elements.create('cardNumber', { placeholder: ' ' }) });
            this.state.cardNumberElement.mount('#txtCreditCardStripe');
            //Create cardexpiry element        
            this.setState({ cardExpiryElement: this.state.elements.create('cardExpiry', { placeholder: ' ' }) });
            this.state.cardExpiryElement.mount('#txtExpireStripe');
            //Create cardcvc element        
            this.setState({ cardCvcElement: this.state.elements.create('cardCvc', { placeholder: ' ' }) });
            this.state.cardCvcElement.mount('#txtCcvStripe');
        }, 500);
    }

    isFormValid = () => {

        var state = this.state;
        var valid = true;
        var paymentStripeRequired = this.props.paymentStripe ? true : false;

        this.state.fields.map((field) => {
            field.isRequiredError = false;
            field.isNumericError = false;
            field.isCompareError = false;

            if (field.paymentAccountTypeID === state.paymentAccountTypeID && (field.paymentStripeRequired === paymentStripeRequired || field.paymentStripeRequired === 'always')) {
                field.value = this.state[field.name];

                if (field.isRequired && !field.value) {
                    field.isRequiredError = true;
                    valid = false;
                }

                if (field.isNumeric && field.value && !validator.isDecimal(field.value)) {
                    field.isNumericError = true;
                    valid = false;
                }

                if (!field.isRequiredError && field.isCompare && this.state[field.compareField] !== field.value) {
                    field.isCompareError = true;
                    valid = false;
                }

                if (field.onValidating) {
                    valid = valid && field.onValidating(field, undefined, this.props);
                }
            }

            return field;
        });

        this.setState(state);

        if (valid) {
            this.props.setPaymentError('');
        } else {
            this.props.setPaymentError(T.translate('common.thereAreMissingFields'));
        }

        return valid;
    }

    onSubmit = (e) => {
        //CV ZD14560 2020/03/26 adding this validation if stripe PK is enable,  then the token will be and the payment account will be submited
        if (this.state.paymentStripePK && this.state.paymentAccountTypeID === '3') {
            var options = {
                address_zip: this.state.postalCode
            };
            //Create stripe token for this card, after that it is submited to the backend among with the other card information not sensitive
            this.state.stripe.createToken(this.state.cardNumberElement, options).then((result) => {                
                if (result.error) {
                    // Inform the customer that there was an error generating the token.
                    this.props.setPaymentError(result.error.message);
                } else {
                    this.setState({ paymentToken: result.token.id });
                    this.setState({ cardNumberValue: '************' + result.token.card.last4 });
                    this.setState({ expireMonth: result.token.card.exp_month });
                    this.setState({ expireYear: result.token.card.exp_year });
                    this.setState({ postalCode: this.state.postalCode });
                    this.setState({ ccv: 123 });
                    this.props.savePaymentAccount(this.state);
                }
            });
        } else if (this.props.systemProperties.isSlimCDEnabled) {
            let processor = this.state.slimCD;
            let expireMonth = 0;
            let expireYear = 0;
            let firstName = "";
            let lastName = "";
            let props = this.props;
            let state = this.state;
            console.log(this.state);
            if (this.state.expiration.split('/').length === 2) {
                expireMonth = this.state.expiration.split('/')[0];
                expireYear = this.state.expiration.split('/')[1];
            }
            if (this.state.cardNameOnAccount.split(' ').length === 1) {
                firstName = this.state.cardNameOnAccount.split(' ')[0];
            }
            if (this.state.cardNameOnAccount.split(' ').length >= 2) {
                firstName = this.state.cardNameOnAccount.split(' ')[0];
                lastName = this.state.cardNameOnAccount.split(' ')[1];
            }

            processor.Transact.ProcessTransaction({
                "username": props.systemProperties.slimCDUsername,
                "transtype": "LOAD",
                "amount": "0.00",
                "cardnumber": this.state.cardNumberValue,
                "expmonth": expireMonth,
                "expyear": expireYear,
                "cvv2": this.state.ccv,
                "first_name": firstName,
                "last_name": lastName
            }, function (reply) {
                if (reply.datablock != undefined && reply.datablock.gateid != undefined)

                    if (reply.response == "Success") {
                        state.paymentToken = reply.datablock.gateid;
                        props.savePaymentAccount(state);
                    }
                    else {
                        alert('There was a problem processing the payment:\r\n' + reply.description);
                    }
            });
        }
        else {
            if (this.isFormValid()) {
                this.props.savePaymentAccount(this.state);
            }
        }

    }

    reactPlaidSuccess = (token, metaData) => {
        this.setState({ account_id: metaData.account_id, public_token: metaData.public_token, reactPlaidOpen: false });
    }

    getExpirationDateError = (field) => {
        if (field.isRequiredError) {
            if (field.isNotMinDateError) {
                return 'accountPay.notMinimumDate';
            }
            else {
                if (field.isInvalidDateFormatError) {
                    return 'accountPay.invalidDateFormat';
                }
            }
            return 'common.thisFieldIsRequired';
        }

        return '';
    }

    getCvvError = (field) => {
        if (field.isRequiredError) {
            if (field.isInvalidCvv) {
                return 'accountPay.invalidCvv';
            }
            return 'common.thisFieldIsRequired';
        }

        return '';
    }

    getZipCodeError = (field) => {
        if (field.isRequiredError) {
            if (field.isInvalidZipCode) {
                return 'accountPay.invalidZipCode';
            }
            return 'common.thisFieldIsRequired';
        }

        return '';
    }

    getCreditCardMask = (cardNumber) => {
        var cardInfo = CommonStore.getCardInfo(cardNumber, false);
        return cardInfo.mask;
    }

    render() {
        return (
            <div className="payment-section">
                <div className="left-sidebar">
                    <div className="p-main">

                        <LoadingOverlay loading={this.props.paymentLoading} />

                        <div className="page-header">
                            <div className="page-title">{T.translate('paymentMethod.title')}</div>
                        </div>
                        <div className="diverter" />

                        {!this.props.showAddPaymentMethod && <div>

                            {this.props.paymentAccounts && this.props.paymentAccounts.map(pa =>
                                <PaymentMethodCollapse pa={pa} key={pa.paymentAccountID} />
                            )}

                            <br />

                            <MessageError>{this.props.paymentErrorMessage}</MessageError>

                            <a href="javascript:;" className="payment-link d-inline-block" onClick={this.toggleAddPaymentMethod}>
                                <img src={paymentIcon} alt="payment" /><span>{T.translate('autopay.addPaymentMethod')}</span>
                            </a>
                        </div>}

                        {this.props.showAddPaymentMethod && <div>
                            <h6 className="card-title">{T.translate('paymentMethod.paymentMethodType')}</h6>
                            <div className="inputGroup">
                                <input name="paymentAccountTypeID" id="paymentAccountTypeCreditCard" type="radio" value="3" checked={this.state.paymentAccountTypeID === '3'} onChange={this.onChange} />
                                <label htmlFor="paymentAccountTypeCreditCard"><span className="credit-card-icon" />{T.translate('paymentMethod.creditCard')}</label>
                            </div>
                            <div className="inputGroup">
                                <input name="paymentAccountTypeID" id="paymentAccountTypeECheck" value="4" type="radio" checked={this.state.paymentAccountTypeID === '4'} onChange={this.onChange} />
                                <label htmlFor="paymentAccountTypeECheck"><span className="bank-icon" />{T.translate('paymentMethod.bankAccount')}</label>
                            </div>

                            {this.state.paymentAccountTypeID === '3' && <div className="credit-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="cardAccountName">{T.translate('paymentMethod.cardNickname')}</label>
                                            <input type="text" id="cardAccountName" name="cardAccountName" value={this.state.cardAccountName} onChange={this.onChange} className={"form-control " + (this.state.fields[0].isRequiredError ? 'is-invalid' : '')} />
                                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                            <span className="input-details">{T.translate('paymentMethod.giveThisCardNickname')}</span>
                                        </div>

                                        {!this.props.getPaymentStripePK() &&<div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group credit-card-input">
                                                    <label htmlFor="cardNumberValue">{T.translate('paymentMethod.cardNumber')}</label>
                                                    <InputMask mask={this.getCreditCardMask(this.state.cardNumberValue)} placeholder="#### #### #### ####" id="cardNumberValue" name="cardNumberValue" value={this.state.cardNumberValue} onChange={this.onChange} className={"form-control " + (this.state.fields[1].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="expiration">{T.translate('paymentMethod.expiration')}</label>
                                                    <InputMask mask="99/99" placeholder="MM/YY" id="expiration" name="expiration" value={this.state.expiration} onChange={this.onChange} className={"form-control " + (this.state.fields[2].isRequiredError ? 'is-invalid' : '')} />
                                                    <div className="invalid-feedback">{T.translate(this.getExpirationDateError(this.state.fields[2]))}</div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label htmlFor="ccv">{T.translate('paymentMethod.securityCode')}</label>
                                                    <input type="number" id="ccv" name="ccv" maxLength={4} value={this.state.ccv} onChange={this.onChange} className={"form-control " + (this.state.fields[3].isRequiredError ? 'is-invalid' : '')} />
                                                    <div className="invalid-feedback">{T.translate(this.getCvvError(this.state.fields[3]))}</div>
                                                </div>
                                            </div>
                                        </div>}

                                        {this.props.getPaymentStripePK() &&
                                            <div>                                                
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group credit-card-input">
                                                            <label htmlFor="cardNumberValue">{T.translate('accountPay.cardNumber')}</label>
                                                            <div id="txtCreditCardStripe" style={{ border: '1px solid #f1f3f4', height: '54px', padding: '18px 60px', borderRadius: '2rem', backgroundColor: '#f1f3f4' }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="expiration">{T.translate('accountPay.expiration')}</label>
                                                            <div id="txtExpireStripe" style={{ border: '1px solid #f1f3f4', height: '54px', padding: '18px 17px', borderRadius: '2rem', backgroundColor: '#f1f3f4' }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="ccv">{T.translate('accountPay.securityCode')}</label>
                                                            <div id="txtCcvStripe" style={{ border: '1px solid #f1f3f4', height: '54px', padding: '18px 17px', borderRadius: '2rem', backgroundColor: '#f1f3f4' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!this.props.getPaymentStripePK() && <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="cardNameOnAccount">{T.translate('paymentMethod.nameOnCard')}</label>
                                                    <input type="text" id="cardNameOnAccount" name="cardNameOnAccount" value={this.state.cardNameOnAccount} onChange={this.onChange} className={"form-control " + (this.state.fields[4].isRequiredError ? 'is-invalid' : '')} />
                                                    <div className="invalid-feedback">{T.translate('paymentMethod.holderNameRequired')}</div>
                                                </div>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="address1">{T.translate('paymentMethod.billingAddress')}</label>
                                                    <input type="text" id="address1" name="address1" onChange={this.onChange} value={this.state.address1} className={"form-control " + (this.state.fields[11].isRequiredError ? 'is-invalid' : '')} />
                                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="address2">{T.translate('customerProfile.aptNumber')}</label>
                                                    <input type="text" className="form-control" id="address2" name="address2" onChange={this.onChange} value={this.state.address2} />
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="city">{T.translate('customerProfile.city')}</label>
                                                    <input type="text" id="city" name="city" onChange={this.onChange} value={this.state.city} className={"form-control " + (this.state.fields[12].isRequiredError ? 'is-invalid' : '')} />
                                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="state">{T.translate('customerProfile.state')}</label>
                                                    <select className="form-control" id="state" name="state" onChange={this.onChange} value={this.state.state}>                                                        
                                                        {
                                                            this.props.statesList.map((state, i) =>
                                                            <option key={i} value={state.value}>{state.name}</option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="postalCode">{T.translate('customerProfile.zipCode')}</label>
                                                    <input type="text" id="postalCode" name="postalCode" onChange={this.onChange} value={this.state.postalCode} className={"form-control " + (this.state.fields[5].isRequiredError ? 'is-invalid' : '')} />
                                                    <div className="invalid-feedback">{T.translate(this.getZipCodeError(this.state.fields[5]))}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <MessageError>{this.props.paymentErrorMessage}</MessageError>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <a href="javascript:;" onClick={this.toggleAddPaymentMethod} className="btn btn-outline-dark mr-3">{T.translate('common.cancel')}</a>
                                                    <LoadingButton
                                                        className="btn btn-primary"
                                                        onClick={this.onSubmit}
                                                        loading={this.props.paymentLoading ? 'true' : 'false'}>
                                                        {T.translate('paymentMethod.savePaymentMethod')}
                                                    </LoadingButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                            {this.state.paymentAccountTypeID === '4' && <div className="bank-account">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="bankAccountName">{T.translate('paymentMethod.bankAccountNickname')}</label>
                                                    <input type="text" id="bankAccountName" name="bankAccountName" value={this.state.bankAccountName} onChange={this.onChange} className={"form-control " + (this.state.fields[6].isRequiredError ? 'is-invalid' : '')} />
                                                    <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                    <span className="input-details">{T.translate('paymentMethod.giveThisBankAccountNickname')}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="bankNameOnAccount">{T.translate('paymentMethod.accountHolderName')}</label>
                                                    <input type="text" id="bankNameOnAccount" name="bankNameOnAccount" value={this.state.bankNameOnAccount} onChange={this.onChange} className={"form-control " + (this.state.fields[13].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                                                    <div className="invalid-feedback">{T.translate('paymentMethod.holderNameRequired')}</div>
                                                </div>
                                            </div>
                                        </div>

                                        {!this.props.paymentStripe && <div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="bankAccountNumber">{T.translate('paymentMethod.bankAccountNumber')}</label>
                                                        <input type="text" id="bankAccountNumber" maxLength={17} name="bankAccountNumber" value={this.state.bankAccountNumber} onChange={this.onChange} className={"form-control " + (this.state.fields[7].isRequiredError ? 'is-invalid' : '')} />
                                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="bankAccountType">{T.translate('autopay.bankAccountType')}</label>
                                                        <select className="form-control" id="bankAccountType" name="bankAccountType" value={this.state.bankAccountType} onChange={this.onChange}>
                                                            {
                                                                this.props.systemProperties.bankAccountTypes.map(function (type) {
                                                                    return <option key={type.value} value={type.value}>{type.name}</option>;
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="bankRoutingNumber">{T.translate('paymentMethod.routingNumber')}</label>
                                                        <InputMask mask="999999999" id="bankRoutingNumber" name="bankRoutingNumber" value={this.state.bankRoutingNumber} onChange={this.onChange} className={"form-control " + (this.state.fields[8].isRequiredError ? 'is-invalid' : '')} />
                                                        <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="bankRoutingNumberConfirm">{T.translate('paymentMethod.confirmRoutingNumber')}</label>
                                                        <InputMask mask="999999999" id="bankRoutingNumberConfirm" name="bankRoutingNumberConfirm" value={this.state.bankRoutingNumberConfirm} onChange={this.onChange} className={"form-control " + (this.state.fields[9].isRequiredError || this.state.fields[9].isCompareError ? 'is-invalid' : '')} />
                                                        <div className="invalid-feedback">
                                                            {this.state.fields[9].isRequiredError ? T.translate('common.thisFieldIsRequired') : ''}
                                                            {this.state.fields[9].isCompareError ? T.translate('paymentMethod.enterSameValue') : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {this.props.paymentStripe && <div>

                                            <div className="row form-group">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <button className="btn" onClick={() => this.setState({ reactPlaidOpen: true })}>{T.translate('accountPay.clickSelectBankAccount')}</button>
                                                        {this.state.fields[10].isRequiredError && <div className="invalid-feedback" style={{ display :'block'}}>{T.translate('common.thisFieldIsRequired')}</div>}
                                                    </div>
                                                </div>
                                            </div>

                                            <ReactPlaid
                                                clientName={this.props.paymentStripe.clientName}
                                                product={this.props.paymentStripe.product}
                                                apiKey={this.props.paymentStripe.apiKey}
                                                env={this.props.paymentStripe.env}
                                                selectAccount={this.props.paymentStripe.selectAccount}
                                                open={this.state.reactPlaidOpen}
                                                onSuccess={(token, metaData) => this.reactPlaidSuccess(token, metaData)}
                                                onExit={() => this.setState({ reactPlaidOpen: false })}
                                            />
                                        </div>}

                                        <MessageError>{this.props.paymentErrorMessage}</MessageError>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <a href="javascript:;" onClick={this.toggleAddPaymentMethod} className="btn btn-outline-dark mr-3">{T.translate('common.cancel')}</a>
                                                    <LoadingButton
                                                        className="btn btn-primary"
                                                        onClick={this.onSubmit}
                                                        loading={this.props.paymentLoading ? 'true' : 'false'}>
                                                        {T.translate('paymentMethod.savePaymentMethod')}
                                                    </LoadingButton>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                {!this.props.paymentStripe && <img src={routing} className="img-fluid routing-pic" alt="routing" />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        </div>}

                    </div>
                </div>

                <PaymentSidebar />
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common, ...state.payment }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators }, dispatch)
)(PaymentMethod);
