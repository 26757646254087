import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as UserStore from '../../store/User';
import LoadingOverlay from '../shared/LoadingOverlay';
import DataGrid from '../shared/DataGrid';
import Select from 'react-select';

const Scroll = require('react-scroll');
const planIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/plan-icon.png`);
const pdf = require('../../images/pdf.png');
const download = require('../../images/download.png');

class Documents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountDocuments: [],
            documentCategory: { },
            documentCategories: [
                { value: 1, label: T.translate('documents.planDocumentsOption') },
                { value: 2, label: T.translate('documents.disconectNoticeDocumentsOption') }
            ]
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('documents.title');
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.accountDocuments && nextProps.accountDocuments != this.props.accountDocuments) {
            var accountDocuments = nextProps.accountDocuments;
            let hrefValue = this.hrefValue;
            accountDocuments.map(doc => {
                doc.documentURL = doc.documentURL ? doc.documentURL : hrefValue(doc.accountDocumentID);
                return doc;
            });

            this.setState({ accountDocuments });
        }        
    }

    hrefValue = (accountDocumentID) => {
        var token = this.props.getPrivateToken();
        var accountID = this.props.account.accountID;
        var url = 'api/data/Document?accountID=' + accountID + '&accountDocumentID=' + accountDocumentID + '&t=' + encodeURIComponent(token.replace('bearer ', ''));
        return url;
    }

    handleChange = (selectedOption) => {        
        this.setState({ documentCategory: selectedOption });
        this.props.getPlanAndDocumentsByCategory(selectedOption);
    }

    getExternalRenewalUrl = () => {
        var renewalUrl = this.props.systemProperties.myAcctRenewalUrlRedirection.replace("{accountNumber}", this.props.account.accountNumber);
        return renewalUrl;
    }

    hasExternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection !== '') {
            return true;
        }
        return false;
    }

    hasInternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection === '') {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('documents.pageTitle')}</div>
                </div>

                <div className="row" style={{ marginTop: '20px' }}>

                    <div className="col-sm-6">
                        <h6 className="card-title">{T.translate('documents.selectDocumentType')}</h6>
                    </div>

                    <div className="col-sm-12">
                        <div className="form-group">
                            <Select
                                value={this.state.documentCategory}
                                options={this.state.documentCategories}
                                onChange={this.handleChange}                               
                            />
                        </div>
                    </div>
                </div>

                <DataGrid usePaging={true} dataKey="accountTransactionID" expandibleContent={false} pageSize="15" className="table-alternating">
                    {{
                        rows: this.state.accountDocuments,
                        columns: [
                            { columnId: 'createDate', displayText: 'Date', dataType: 'date', format: 'MM/dd/yyyy', bold: true, italic: true, style: { width: '20%' } },
                            { columnId: 'description', displayText: 'Document', dataType: 'text', className: 'td-border-left td-numeric', headerClass: 'td-border-left' },
                            { columnId: 'documentURL', displayText: 'Action', dataType: 'download', className: 'td-border-left td-numeric', headerClass: 'td-border-left', bold: true, style: { width: '25%' } }
                        ]
                    }}
                </DataGrid>

            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(Documents);
