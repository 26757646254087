import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as UserStore from '../store/User';
import * as CommonStore from '../store/Common';
import ExternalLink from './shared/ExternalLink';
import LoadingButton from './shared/LoadingButton';
import MessageError from './shared/MessageError';
import InputMask from 'react-input-mask';
import Password from './shared/PasswordInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import validator from 'validator';
import T from 'i18n-react';
import { Tooltip } from 'reactstrap';

const Scroll = require('react-scroll');

class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            accountNumber: '',
            zipCode: '',
            phone: '',
            email: '',
            userName: '',
            password: '',
            passwordConfirmation: '',
            userNameEmailAddress: '',
            userNameMobilePhone: '',
            terms: false,
            step: 1,
            useEmailAddress: true,
            passwordTooltipOpen: false,
            fields: [//don't change field order - its used to validate the form
                { name: 'accountNumber', isRequired: true, step: 1 },
                { name: 'zipCode', isRequired: true, step: 1 },
                { name: 'phone', isRequired: false, step: 1 },
                { name: 'email', isRequired: false, isEmail: true, step: 1 }, //3
                { name: 'userNameEmailAddress', step: 2, isEmail: true, conditionField: 'useEmailAddress', conditionValue: true },
                { name: 'userNameMobilePhone', step: 2, conditionField: 'useEmailAddress', conditionValue: false },
                { name: 'password', isRequired: true, step: 2, isPassword: true },
                { name: 'passwordConfirmation', isRequired: true, isCompare: true, compareField: 'password', step: 2 },
                { name: 'terms', isRequired: true, step: 2 } //8
            ],
            tooltipPhoneOpen: false,
            tooltipEmailOpen: false
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('register.title');

        this.props.clearUserState();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.registerConfirmation) {
            this.setState({ step: 3 });
            Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        }

        if (!this.props.registerUserFound && nextProps.registerUserFound && nextProps.registerPerson.personID) {

            let userNameMobilePhone = '';
            const p = nextProps.registerPerson;
            if (nextProps.registerPerson.personContacts) {
                let cell = nextProps.registerPerson.personContacts.filter(x => x.contactTypeID === 1)[0];

                if (cell && cell.contactValue) {
                    userNameMobilePhone = cell.contactValue;
                }
            }

            this.setState({ step: 2, userNameEmailAddress: p.emailAddress, userNameMobilePhone });
            Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        }
    }

    toggleEmailTooltip = () => {
        this.setState({
            tooltipEmailOpen: !this.state.tooltipEmailOpen
        });
    }

    togglePhoneTooltip = () => {
        this.setState({
            tooltipPhoneOpen: !this.state.tooltipPhoneOpen
        });
    }

    toggleUseEmailAddress = (e) => {
        this.setState({ useEmailAddress: !this.state.useEmailAddress });
    }

    onChange = (e) => {

        this.hidePasswordTooltip();

        let state = this.state;
        let fields = state.fields.filter(x => x.name === e.target.name);

        if (fields && fields.length === 1) {

            let field = fields[0];

            if (field.isRequired && field.isRequiredError && e.target.value) {
                field.isRequiredError = false;
            }

            if (!field.isRequired && field.isRequiredError && field.conditionField && e.target.value && field.conditionValue === state[field.conditionField]) {
                field.isRequiredError = false;
            }

            if (!field.isRequiredError && field.isEmailError && validator.isEmail(e.target.value)) {
                field.isEmailError = false;
            }

            if (!field.isRequiredError && field.isPasswordError && field.value && CommonStore.checkPasswordRules(e.target.value)) {
                field.isPasswordError = false;
            }

            if (!field.isRequiredError && field.isCompareError && this.state[field.compareField] === e.target.value) {
                field.isCompareError = false;
            }
        }

        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    goStep1 = () => {
        this.setState({ step: 1 });
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
    }

    goStep2 = () => {
        if (this.isFormValid()) {
            this.props.getRegister(this.state);
        }
    }

    goStep3 = () => {
        if (this.isFormValid()) {
            this.props.register(this.state);
        }
    }

    isFormValid = () => {

        var state = this.state;
        var valid = true;
        state.passwordTooltipOpen = false;

        this.state.fields.map((field) => {

            if (field.step === state.step) {

                field.value = this.state[field.name];
                //clear errors
                field.isRequiredError = false;
                field.isEmailError = false;
                field.isCompareError = false;
                field.isPasswordError = false;

                if (field.isRequired && !field.value) {
                    field.isRequiredError = true;
                    valid = false;
                }

                if (!field.isRequiredError && field.conditionField && !field.value && field.conditionValue === state[field.conditionField]) {
                    field.isRequiredError = true;
                    valid = false;
                }

                if (!field.isRequiredError && field.isEmail && field.value && !validator.isEmail(field.value)) {
                    field.isEmailError = true;
                    valid = false;
                }

                if (!field.isRequiredError && field.isCompare && this.state[field.compareField] !== field.value) {
                    field.isCompareError = true;
                    valid = false;
                }

                if (!field.isPasswordError && field.isPassword && field.value) {
                    field.isPasswordError = !CommonStore.checkPasswordRules(field.value);
                    valid = valid && !field.isPasswordError;

                    if (!valid) {
                        state.passwordTooltipOpen = true;
                    }
                }
            }

            return field;
        });

        this.setState(state);

        if (valid) {

            if (!this.state.email && !this.state.phone && this.state.step === 1) {
                valid = false;
                this.props.setError('Mobile Phone or Email Address required. Please enter at least one of the fields.');
            } else {
                this.props.setError('');
            }
        } else {
            this.props.setError(T.translate('common.thereAreMissingFields'));
        }

        return valid;
    }

    showPasswordTooltip = () => {
        this.setState({ passwordTooltipOpen: true });
    }

    hidePasswordTooltip = () => {
        this.setState({ passwordTooltipOpen: false });
    }

    render() {
        return (
            <div className="my-account-md-container">
                <div className="my-account-md-content">
                    <div className="page-header">
                        <div className="page-title">{T.translate('register.title')}</div>
                    </div>
                    <div className="diverter" />
                    {this.state.step === 1 && <div>
                        <div className="form-group mt-4">
                            <label htmlFor="accountNumber">{T.translate('register.accountNumber')}</label>
                            <input type="text" id="accountNumber" name="accountNumber" value={this.state.accountNumber} onChange={this.onChange} className={"form-control " + (this.state.fields[0].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="zipCode">{T.translate('register.zipCode')}</label>
                            <input type="text" id="zipCode" name="zipCode" value={this.state.zipCode} onChange={this.onChange} className={"form-control " + (this.state.fields[1].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>

                        <div className="form-group">
                            <label id="lblMobilePhone" htmlFor="phone">{T.translate('register.mobilePhone')}</label>
                            <Tooltip placement="right-end" isOpen={this.state.tooltipPhoneOpen} target="lblMobilePhone" toggle={this.togglePhoneTooltip}>
                                <div>{T.translate('register.phoneOnAccount')}</div>
                            </Tooltip>
                            <InputMask mask="(999) 999-9999" id="phone" name="phone" value={this.state.phone} onChange={this.onChange} className={"form-control " + (this.state.fields[2].isRequiredError ? 'is-invalid' : '')} autoComplete="new-password" />
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>

                        <div className="form-group">
                            <label id="lblEmail" htmlFor="email">{T.translate('register.emailAddress')}</label>
                            <Tooltip placement="right-end" isOpen={this.state.tooltipEmailOpen} target="lblEmail" toggle={this.toggleEmailTooltip}>
                                <div>{T.translate('register.emailOnAccount')}</div>
                            </Tooltip>
                            <input type="text" id="email" name="email" value={this.state.email} onChange={this.onChange} className={"form-control " + (this.state.fields[3].isRequiredError || this.state.fields[3].isEmailError ? 'is-invalid' : '')} autoComplete="new-password" />
                            <div className="invalid-feedback">{this.state.fields[3].isEmailError && T.translate('common.provideValidEmail')}</div>
                        </div>

                        <MessageError>{this.props.userErrorMessage}</MessageError>

                        <LoadingButton
                            className="btn btn-primary btn-lg"
                            disabled={this.props.userLoading || this.props.commonLoading}
                            loading={this.props.userLoading || this.props.commonLoading ? 'true' : 'false'}
                            onClick={this.goStep2}>
                            {T.translate('register.goToStep2')}
                        </LoadingButton>

                        <p className="d-inline-block ml-0 mt-4 mt-sm-0 ml-sm-4">{T.translate('register.alreadyHaveUsername')}<NavLink to="/" className="a-link ml-3 ml-sm-4">{T.translate('register.loginHere')}</NavLink></p>
                    </div>}

                    {this.state.step === 2 && <div>

                        <p>{T.translate('register.welcomeToNewCustomerPortal')}</p>

                        {this.state.useEmailAddress && <div className="form-group mt-4">
                            <label htmlFor="userNameEmailAddress">{T.translate('register.emailAddress')}</label>
                            <label style={{ float: 'right' }}><a href="javascript:;" onClick={this.toggleUseEmailAddress}>{T.translate('register.useMobileNumber')}</a></label>
                            <input type="text" id="userNameEmailAddress" name="userNameEmailAddress" onChange={this.onChange} value={this.state.userNameEmailAddress} className={"form-control " + (this.state.fields[4].isRequiredError || this.state.fields[4].isEmailError ? 'is-invalid' : '')} autoComplete="off" />
                            {this.state.fields[4].isRequiredError && <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>}
                            {this.state.fields[4].isEmailError && <div className="invalid-feedback">{T.translate('common.provideValidEmail')}</div>}
                        </div>}

                        {!this.state.useEmailAddress && <div className="form-group mt-4">
                            <label htmlFor="userNameMobilePhone">{T.translate('register.mobilePhone')}</label>
                            <label style={{ float: 'right' }}><a href="javascript:;" onClick={this.toggleUseEmailAddress}>{T.translate('register.useEmailAddress')}</a></label>
                            <InputMask mask="(999) 999-9999" id="userNameMobilePhone" name="userNameMobilePhone" onChange={this.onChange} value={this.state.userNameMobilePhone} className={"form-control " + (this.state.fields[5].isRequiredError ? 'is-invalid' : '')} autoComplete="off" />
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>}

                        <div className="form-group">
                            <Password
                                id="password"
                                name="password"
                                className={((this.state.fields[6].isRequiredError || this.state.fields[6].isPasswordError) ? 'is-invalid' : '')}
                                labelText={T.translate('register.createPassword')}
                                maxLength={30}
                                messageError={this.state.fields[6].isRequiredError ? T.translate('common.thisFieldIsRequired') : (this.state.fields[6].isPasswordError ? T.translate('common.passwordRulesError') : '')}
                                onChange={this.onChange}
                                value={this.state.password}
                                showPasswordRules
                                showTooltip={this.showPasswordTooltip}
                                hideTooltip={this.hidePasswordTooltip}
                                tooltipOpen={this.state.passwordTooltipOpen}
                            />
                        </div>

                        <div className="form-group">
                            <Password
                                id="passwordConfirmation"
                                name="passwordConfirmation"
                                className={((this.state.fields[7].isRequiredError || this.state.fields[7].isCompareError) ? 'is-invalid' : '')}
                                labelText={T.translate('register.confirmPassword')}
                                maxLength={30}
                                messageError={this.state.fields[7].isRequiredError ? T.translate('common.thisFieldIsRequired') : (this.state.fields[7].isCompareError ? T.translate('common.enterSameValue') : '')}
                                onChange={this.onChange}
                                value={this.state.passwordConfirmation}
                            />
                        </div>

                        {false && <div>
                            <div className="my-or mt-4 mt-sm-5 mb-4 mb-sm-5"><span>{T.translate('register.orLoginUsing')}</span></div>
                            <ul className="login-social clearfix">
                                <li><a href="javascript:;" className="facebook"><FontAwesomeIcon icon={faFacebookF} />Facebook</a></li>
                                <li><a href="javascript:;" className="twitter"><FontAwesomeIcon icon={faTwitter} />Twitter</a></li>
                                <li><a href="javascript:;" className="linkedin"><FontAwesomeIcon icon={faLinkedinIn} />LinkedIn</a></li>
                            </ul>
                        </div>}

                        <div className="custom-control custom-checkbox mt-4 mt-sm-5 mb-4 mb-sm-5">
                            <input type="checkbox" id="terms" name="terms" onChange={this.onChange} className={"custom-control-input " + (this.state.fields[8].isRequiredError ? 'is-invalid' : '')} />
                            <label className="custom-control-label barlow text-body" htmlFor="terms">{T.translate('register.byRegisteringYouAgree', this.props.systemProperties)} <ExternalLink href={this.props.systemProperties.marketerTosUrl} className="a-link">{T.translate('register.termsOfService')}</ExternalLink></label>
                            <div className="invalid-feedback">{T.translate('common.thisFieldIsRequired')}</div>
                        </div>

                        <MessageError>{this.props.userErrorMessage}</MessageError>

                        <button className="btn btn-lg mr-sm-4" type="button" onClick={this.goStep1}>{T.translate('common.back')}</button>

                        <LoadingButton
                            className="btn btn-primary btn-lg"
                            disabled={this.props.userLoading}
                            loading={this.props.userLoading ? 'true' : 'false'}
                            onClick={this.goStep3}>
                            {T.translate('register.register')}
                        </LoadingButton>

                        <p className="d-inline-block ml-0 mt-4 mt-sm-0 ml-sm-4">{T.translate('register.alreadyHaveUsername')}<NavLink to="/" className="a-link ml-3 ml-sm-4">{T.translate('register.loginHere')}</NavLink></p>
                    </div>}

                    {this.state.step === 3 && <div>
                        <div className="alert alert-success mb-5 mt-4 p-4 fade in alert-dismissible show d-flex">
                            <div className="d-flex">
                                <FontAwesomeIcon className="mr-3" size="2x" icon={faCheckCircle} />
                                <div>
                                    <p>{T.translate('register.verificationEmailSent')}</p>
                                    <p className="font-weight-bold">{T.translate('register.linkExpires')}</p>
                                    <p>{T.translate('register.stepCompleted')}</p>
                                </div>
                            </div>
                        </div>
                        <NavLink to="/" className="btn btn-primary btn-lg">{T.translate('register.returnToLogin')}</NavLink>
                    </div>}

                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(Register);
