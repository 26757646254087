import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as UserStore from '../../store/User';
import LoadingOverlay from '../shared/LoadingOverlay';
import DataGrid from '../shared/DataGrid';

const Scroll = require('react-scroll');
const planIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/plan-icon.png`);
const pdf = require('../../images/pdf.png');
const download = require('../../images/download.png');

class Plans extends Component {

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('plans.title');

        if (this.props.account.accountNumber) {
            this.props.getPlans();
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getPlans();
        }

        if (this.props.accountServicePointID > 0 && this.props.accountServicePointID !== nextProps.accountServicePointID) {
            //if account service point changed then get activity for it
            this.props.getPlans();
        }
    }

    getExternalRenewalUrl = () => {
        var renewalUrl = this.props.systemProperties.myAcctRenewalUrlRedirection.replace("{accountNumber}", this.props.account.accountNumber);
        return renewalUrl;
    }

    hasExternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection !== '') {
            return true;
        }
        return false;
    }

    hasInternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection === '') {
            return true;
        }
        return false;
    }

    render() {
        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('plans.pageTitle')}</div>

                </div>


                {this.props.serviceAgreement.serviceAgreementID && this.props.serviceAgreement.serviceAgreementID > 0 && <React.Fragment>
                    <div className="card mb-5 card-higher">
                        <div className="card-body">
                            <div className="super-sever-flex-box position-relative">

                                <img src={planIcon} alt="plan icon" />
                                <div className="align-self-center">
                                    <h6 className="card-title">{this.props.serviceAgreement.productName}</h6>
                                </div>
                                {this.props.serviceAgreement.formattedStartDate !== '' && <div className="expires-right text-right expires-right-top">
                                    {T.translate('plansDocuments.active')}
                                    <div className="expires-badge">{this.props.serviceAgreement.formattedStartDate}</div>
                                </div>}

                                {this.props.serviceAgreement.formattedEndDate !== '' && <div className="expires-right text-right expires-right-bottom">
                                    {T.translate('plansDocuments.expires')}
                                    <div className="expires-badge">{this.props.serviceAgreement.formattedEndDate}</div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </React.Fragment>}

            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(Plans);
