import { history } from '../index';
import * as CommonState from './Common';
import T from 'i18n-react';
import Cookies from 'universal-cookie';

const initialState = {
    accountLoading: false,
    accountErrorMessage: '',
    accountSourceError: '',
    serviceAgreement: {},
    member: { persons: [] },
    renewalOffer: {
        products: [],
        accountRenewalActions: [],
        renewalModelId: 0
    },
    productOfferSearchStatus: 0,
    serviceRenewed: false,
    accountDocuments: [],
    accountStatements: [],
    transactionsHistory: [],
    accountHeaderBanners: [],
    accountMarketingBanners: [],
    years: [],
    activityMonthlyCategories: [],
    activityMonthlySeries: [],
    activityMonthlySeries2: [],
    costCurrentMonth: 5.43,
    costLastMonth: 6.01,
    costCurrentWeek: 5.28,
    costLastWeek: 5.64,
    isAccountVerificationRequired: false,
    accountPerson: {},
    twoFactorAuthentication: { isResend: false },
    suggestedPaymentObject: {},
    selectedWebProduct: {},
    militaryAccountDocumentFile: '',
    referralName: ''
};

export const actionCreators = {

    login: (user) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;
        fetch(`${state.common.serviceUrl}/AuthenticateUser`, { headers: headers, method: 'POST', body: JSON.stringify(user), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                if (data.isAuthenticated && data.accounts && data.accounts.length > 0) {

                    dispatch({ type: 'REQUEST_ACCOUNT_END' });
                    CommonState.secureStorage.setItem('accountID', data.accounts[0].accountID);
                    CommonState.secureStorage.setItem('accounts', data.accounts);
                    CommonState.secureStorage.setItem('privateToken', 'bearer ' + data.token);

                    const cookies = new Cookies();
                    if (user.rememberMe && data.rememberMeToken) {
                        cookies.set('618e274e-79a9-4cef-aa4e-4ecbe0f492c3', data.rememberMeToken, { path: '/', sameSite: 'strict' });
                    } else {
                        cookies.remove('618e274e-79a9-4cef-aa4e-4ecbe0f492c3');
                    }

                    history.push('/myaccount/dashboard');

                } else if (data.isUserNameUpdateRequired && data.accounts && data.accounts.length > 0) {

                    dispatch({ type: 'SET_USERNAME_UPDATE_REQUIRED', accountPersons: data.accounts, password: user.password });
                    history.push('/usernameupdate');

                } else if (data.isAccountVerificationRequired && data.accounts && data.accounts.length > 0) {

                    dispatch({ type: 'SET_ACCOUNT_VERIFICATION_REQUIRED', isAccountVerificationRequired: true, accountPersons: data.accounts });

                } else if (data.is2FactorAuthenticationRequired && data.accounts && data.accounts.length > 0) {

                    dispatch({ type: 'SET_TFA_BASIC', accountPersons: data.accounts, username: user.username, password: user.password });
                    history.push('/twofactorauthentication');

                } else {
                    dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: T.translate('login.invalidUsernamePassword') });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message + ' ' + T.translate('common.contactCustomerService', state.common.systemProperties) });
            });

        dispatch({ type: 'SET_ACCOUNT_VERIFICATION_REQUIRED', isAccountVerificationRequired: false, accountPerson: {} });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    loginByToken: (user) => (dispatch, getState) => {
        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;
        user.languageID = state.common.langID;

        fetch(`${state.common.serviceUrl}/AuthenticateUserByToken`, { headers: headers, method: 'POST', body: JSON.stringify(user), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                if (data.isAuthenticated && data.accounts && data.accounts.length > 0) {

                    dispatch({ type: 'REQUEST_ACCOUNT_END' });
                    CommonState.secureStorage.setItem('accountID', data.accounts[0].accountID);
                    CommonState.secureStorage.setItem('accounts', data.accounts);
                    CommonState.secureStorage.setItem('privateToken', 'bearer ' + data.token);

                    const cookies = new Cookies();
                    if (user.rememberMe && data.rememberMeToken) {
                        cookies.set('618e274e-79a9-4cef-aa4e-4ecbe0f492c3', data.rememberMeToken, { path: '/', sameSite: 'strict' });
                    } else {
                        cookies.remove('618e274e-79a9-4cef-aa4e-4ecbe0f492c3');
                    }

                    history.push('/myaccount/dashboard');

                } else {
                    dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: T.translate('login.invalidUsernamePassword') });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message + ' ' + T.translate('common.contactCustomerService', state.common.systemProperties) });
            });

            dispatch({ type: 'SET_ACCOUNT_VERIFICATION_REQUIRED', isAccountVerificationRequired: false, accountPerson: {} });

            dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    autoLogin: (user) => (dispatch, getState) => {
        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;
        user.languageID = state.common.langID;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/AuthenticateAutoLogin`, { headers: headers, method: 'POST', body: JSON.stringify(user), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (data.isAuthenticated && data.accounts && data.accounts.length > 0) {

                    dispatch({ type: 'REQUEST_AUTOLOGIN_END' });

                    CommonState.secureStorage.setItem('accountID', data.accounts[0].accountID);
                    CommonState.secureStorage.setItem('accounts', data.accounts);
                    CommonState.secureStorage.setItem('privateToken', 'bearer ' + data.token);
                    history.push('/myaccount/dashboard');

                } else {

                    const cookies = new Cookies();
                    var rememberMe = cookies.get('618e274e-79a9-4cef-aa4e-4ecbe0f492c3') !== undefined;

                    var errorMessage = data.isError ? data.errorMessage : T.translate('login.invalidUsernamePassword');
                    var accountSourceError = data.isExpiredToken ? 'ExpiredToken' : '';

                    var dispatchError = { type: 'SET_ACCOUNT_ERROR', accountErrorMessage: errorMessage, accountSourceError: accountSourceError };
                    if (rememberMe) {
                        removeRememberMe();
                    } else {
                        dispatch(dispatchError);
                    }
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
            });
        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    twoFactorAuthCode: (data) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        data.persons = [];

        if (state.account.accountPersons && state.account.accountPersons.length > 0) {
            state.account.accountPersons.forEach(function (a) {
                data.persons.push(a.persons[0]);
            });
        }

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/TwoFactorAuthentication`, { headers: headers, method: 'POST', body: JSON.stringify(data), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                if (data.isValidRegistration) {
                    dispatch({ type: 'REQUEST_TFA_END', twoFactorAuthentication: { isResend: false } });

                    const cookies = new Cookies();
                    cookies.set('twf4a9th', data.twoFactorAuthCode, { path: '/', sameSite: 'strict' });

                    CommonState.secureStorage.setItem('accountID', data.accounts[0].accountID);
                    CommonState.secureStorage.setItem('accounts', data.accounts);
                    CommonState.secureStorage.setItem('privateToken', 'bearer ' + data.token);

                    history.push('/myaccount/dashboard');

                } else {
                    dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: 'twoFactorAuthentication.invalidCode' });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_TFA_START' });
    },

    generateNewAuthCode: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        let req = {};
        req.username = state.account.username;
        req.password = state.account.password;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/NewAuthenticationCode`, { headers: headers, method: 'POST', body: JSON.stringify(req), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (!data.isError) {
                    dispatch({ type: 'REQUEST_TFA_END', twoFactorAuthentication: { isResend: true } });
                } else {
                    dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: 'twoFactorAuthentication.cantGenerateANewCode' });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_TFA_START' });
    },

    clearAccountState: (cleanRememberMe = false) => (dispatch, getState) => {

        const cookies = new Cookies();
        var rememberMe = cookies.get('618e274e-79a9-4cef-aa4e-4ecbe0f492c3');

        if (cleanRememberMe && rememberMe) {
            removeRememberMe();
        }

        clearAccountState(dispatch, getState, { skipRedirect: false });
    },

    getStatements: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        var from = new Date();
        var to = new Date();

        from.setHours(0, 0, 0, 0);
        from = CommonState.addYearsToDate(from, - 2);

        var fromString = CommonState.formatDate(from, 'MM/DD/YYYY');
        var toString = CommonState.formatDate(to, 'MM/DD/YYYY');

        var request = { AccountID: state.common.accountID, FromDate: fromString, ToDate: toString };

        var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/BillStatements?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(rows => {

                if (rows) {
                    var years = [];

                    rows.map(function (row, i) {
                        row.year = CommonState.formatDate(row.billDate, 'YYYY');
                        if (!years.includes(row.year))
                            years.push(row.year);
                        return row;
                    });

                    years.sort();
                    years.reverse();

                    dispatch({ type: 'RESPONSE_MONTHLY_STATEMENTS', accountStatements: rows, years });
                } else {
                    dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: T.translate('quickPay.noAccountFound') });
                }

            })
            .catch(function (error) {

                dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getAccountActivity: (chartTypeID, date) => (dispatch, getState) => {

        const state = getState();

        let meterReadTypeID = state.common.meterReadTypeID;

        if (!meterReadTypeID) {
            meterReadTypeID = 2; //set interval by default
        }

        getAccountBanners(dispatch, getState);

        getSuggestedPaymentObject(dispatch, getState);

        if (chartTypeID === '1') {
            getAccountActivityMonthly(dispatch, getState, meterReadTypeID);
        } else if (chartTypeID === '2') {
            getAccountActivityDaily(dispatch, getState, meterReadTypeID);
        } else if (chartTypeID === '3') {
            getAccountActivityInterval(dispatch, getState, date, meterReadTypeID);
        }

    },

    getTransactiondHistory: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        var from = new Date();
        var to = new Date();

        from.setHours(0, 0, 0, 0);
        from.setMonth(from.getMonth() - 1);//Getting last month

        var fromString = CommonState.formatDate(from, 'MM/DD/YYYY');
        var toString = CommonState.formatDate(to, 'MM/DD/YYYY');

        var request = { AccountID: state.common.accountID, FromDate: fromString, ToDate: toString };

        var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');
        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/TransactionsHistory?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(rows => {

                var years = [];

                rows.map(function (row, i) {
                    row.year = CommonState.formatDate(row.date, 'YYYY');
                    if (!years.includes(row.year))
                        years.push(row.year);
                });

                years.sort();
                years.reverse();

                dispatch({ type: 'RESPONSE_TRANSACTION_HISTORY', transactionsHistory: rows, years });
            })
            .catch(function (error) {

                dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getPlanAndDocuments: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let fetchServiceAgreement = fetch(`${state.common.serviceUrl}/ServiceAgreement?AccountID=${state.common.accountID}&AccountServicePointID=${state.common.accountServicePointID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        //TODO: review webmethods
        fetchServiceAgreement.then(sa => {

            sa.formattedEndDate = CommonState.formatDate(sa.endDate, 'MM-DD-YYYY');
            sa.formattedStartDate = CommonState.formatDate(sa.startDate, 'MM-DD-YYYY');

            let fetchAccount = fetch(`${state.common.serviceUrl}/AccountDocuments?AccountID=${state.common.accountID}&ServiceAgreementID=${sa.serviceAgreementID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
                .then(CommonState.handleResponse);

            fetchAccount.then(accountDocuments => {

                sa.formattedEndDate = CommonState.formatDate(sa.endDate, 'MM-DD-YYYY');
                dispatch({ type: 'RESPONSE_PLANS_&_DOCUMENTS', serviceAgreement: sa, accountDocuments: accountDocuments });

            });
        }).catch(function (error) {

            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getPlans: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let fetchServiceAgreement = fetch(`${state.common.serviceUrl}/ServiceAgreement?AccountID=${state.common.accountID}&AccountServicePointID=${state.common.accountServicePointID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        fetchServiceAgreement.then(sa => {
            sa.formattedEndDate = CommonState.formatDate(sa.endDate, 'MM-DD-YYYY');
            sa.formattedStartDate = CommonState.formatDate(sa.startDate, 'MM-DD-YYYY');
            console.log(sa);
            dispatch({ type: 'RESPONSE_PLANS_&_DOCUMENTS', serviceAgreement: sa });
        }).catch(function (error) {

            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getPlanAndDocumentsByCategory: (category) => (dispatch, getState) => {

        const state = getState();

        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let fetchServiceAgreement = fetch(`${state.common.serviceUrl}/ServiceAgreement?AccountID=${state.common.accountID}&AccountServicePointID=${state.common.accountServicePointID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        //TODO: review webmethods
        fetchServiceAgreement.then(sa => {

            let fetchAccount = fetch(`${state.common.serviceUrl}/AccountDocumentsByCategory?AccountID=${state.common.accountID}&ServiceAgreementID=${sa.serviceAgreementID}&DocumentsCategory=${category.value}`, { headers: headers, method: 'GET', credentials: "same-origin" })
                .then(CommonState.handleResponse);

            fetchAccount.then(accountDocuments => {

                sa.formattedEndDate = CommonState.formatDate(sa.endDate, 'MM-DD-YYYY');
                dispatch({ type: 'RESPONSE_PLANS_&_DOCUMENTS', serviceAgreement: sa, accountDocuments: accountDocuments });

            });
        }).catch(function (error) {

            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getMemberInfo: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let fetchMembers = fetch(`${state.common.serviceUrl}/MemberInfo?AccountID=${state.common.accountID}&ExcludeAuthMembers=${true}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        fetchMembers.then(data => {
            dispatch({ type: 'RESPONSE_MEMBERS', member: data.member });
        }).catch(function (error) {

            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getDonationAddOnCharges: (productID) => (dispatch, getState) => {
        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        var req = {};
        req.productID = productID;
        req.languageID = state.common.langID;

        var queryString = Object.keys(req).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(req[key])).join('&');
        let fetchTask = fetch(`${state.common.serviceUrl}/ProductPrograms?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (data) {
                    dispatch({ type: 'RECEIVE_PROGRAMS', addOnCharges: data.addOnCharges, utilityPrograms: data.utilityProgramList });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_IS_ERROR', isError: true, errorMessage: error.message });
            });
    },

    getMemberPrograms: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');
        
        let fetchMemberProgram = fetch(`${state.common.serviceUrl}/MemberProgram?AccountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        fetchMemberProgram.then(data => {
            dispatch({ type: 'RESPONSE_LOCATION_PROGRAMS', memberProgram: data.accountDonationProgram });           
            if (data.isError) {
                dispatch({ type: 'RESPONSE_LOCATION_PROGRAMS_ERROR', isError: data.isError, memberProgramError: data.errorMessage });
            }

        }).catch(function (error) {
            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    createMemberProgram: (view, productID) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let request = {};
        request.accountID = state.common.accountID;
        request.accountServicePointID = state.common.accountServicePointID;

        request.externalUser = "myAccount";
        request.utilityProgramIDs = view.utilityProgramIDs;

        let accountDonationProgramRequest = {};
        accountDonationProgramRequest.accountID = request.accountID;

        if (state.account.memberProgram.accountDonationProgramID > 0) {
            accountDonationProgramRequest.accountDonationProgramID = state.account.memberProgram.accountDonationProgramID;
            accountDonationProgramRequest.startDate = state.account.memberProgram.startDate;
        }

        if (view.roundupCheck == 1)
            accountDonationProgramRequest.DonationProgramTypeID = 1;
        if (view.membertomemberCheck == 1)
            accountDonationProgramRequest.DonationProgramTypeID = 2;
        if (view.roundupCheck == 1 && view.membertomemberCheck == 1)
            accountDonationProgramRequest.DonationProgramTypeID = 3;
        if (view.roundupCheck == 0 && view.membertomemberCheck == 0)
            accountDonationProgramRequest.DonationProgramTypeID = 0;
        if (view.roundupCheck == -1 && view.membertomemberCheck == -1)
            accountDonationProgramRequest.DonationProgramTypeID = -1;

        request.productID = productID;
        request.accountDonationProgram = accountDonationProgramRequest;

        const formdata = new FormData();

        if (state.account.militaryAccountDocumentFile) {
            formdata.append('files', state.account.militaryAccountDocumentFile);
            request.militaryIDDocumentFileName = state.account.militaryAccountDocumentFile.name;
        }

        if (state.account.referralName)
            request.referralName = state.account.referralName;        

        formdata.append('json', JSON.stringify(request));

        let localHeaders = {
            'Authorization': headers.Authorization
        }

        let fetchMemberProgram = fetch(`${state.common.serviceUrl}/LocationPrograms`, { headers: localHeaders, method: 'POST', body: formdata, credentials: "same-origin" })
            .then(CommonState.handleResponse);

        fetchMemberProgram.then(data => {
            dispatch({ type: 'RESPONSE_LOCATION_PROGRAMS', memberProgram: data.accountDonationProgram });
            dispatch({ type: 'RESPONSE_LOCATION_PROGRAMS_ERROR', isError: data.isError, memberProgramError: data.errorMessage });
            dispatch({ type: 'RESPONSE_LOCATION_PROGRAMS_SUCCESS', isSuccess: !data.isError });           

        }).catch(function (error) {
            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getPromotionDetail: (productID) => (dispatch, getState) => {
        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');
        var req = {};
        req.productID = productID;

        var queryString = Object.keys(req).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(req[key]));
        let fetchTask = fetch(`${state.common.serviceUrl}/GetPromotionByProductID?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (data) {
                    dispatch({ type: 'RECEIVE_PROMOTION_PRODUCT', promotions: data });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_IS_ERROR', isError: true, errorMessage: error.message });
            });
    },

    getProductDocumentFile: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        var from = new Date();
        var to = new Date();

        from.setHours(0, 0, 0, 0);
        from.setMonth(from.getMonth() - 1);//Getting last month

        var fromString = CommonState.formatDate(from, 'MM/DD/YYYY');
        var toString = CommonState.formatDate(to, 'MM/DD/YYYY');

        var request = { AccountID: state.common.accountID, FromDate: fromString, ToDate: toString };

        var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');
        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/TransactionsHistory?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(rows => {

                var years = [];

                rows.map(function (row, i) {
                    row.year = CommonState.formatDate(row.date, 'YYYY');
                    if (!years.includes(row.year))
                        years.push(row.year);
                });

                years.sort();
                years.reverse();

                dispatch({ type: 'RESPONSE_TRANSACTION_HISTORY', transactionsHistory: rows, years });
            })
            .catch(function (error) {

                dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getRenewalOfferActions: () => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let fetchRenewalOfferAction = fetch(`${state.common.serviceUrl}/RenewalOfferActions?AccountID=${state.common.accountID}&AccountServicePointID=${state.common.accountServicePointID}&LanguageID=${state.common.langID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        //TODO: review webmethods
        fetchRenewalOfferAction.then(response => {
            var products = response.renewalModel.webProducts.reverse(p => p.isFeatured).map((prod) => {
                prod.formattedStartDate = CommonState.formatDate(prod.contractStartDate, 'MM-DD-YYYY');
                prod.formattedEndDate = CommonState.formatDate(prod.contractEndDate, 'MM-DD-YYYY');
                prod.docTypes = [...new Set(prod.productDocuments.map(p => p.documentTypeID))];
                return prod;
            });

            var data = {
                products: products,
                accountRenewalActions: response.accountRenewalActionList,
                renewalModelId: response.renewalModel.renewalModelId
            };

            dispatch({ type: 'RESPONSE_RENEWAL_OFFER_ACTION', renewalOffer: data });
        }).catch(function (error) {

            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getRenewalOfferProduct: (offerCode) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let fetchRenewalOfferAction = fetch(`${state.common.serviceUrl}/RenewalOfferProduct?AccountID=${state.common.accountID}&renewalModelId=${state.account.renewalOffer.renewalModelId}&OfferCode=${offerCode}&LanguageID=${state.common.langID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse);

        //TODO: review webmethods
        fetchRenewalOfferAction.then(response => {

            var offeredProducts = response.offeredProducts;
            var renewalOffer = state.account.renewalOffer;
            var productOfferSearchStatus = state.common.constants.searchStatus.NOT_FOUND;

            renewalOffer.products = renewalOffer.products.filter(x => !x.offeredByCode);

            renewalOffer.products = renewalOffer.products.map(function (prod, i) {
                prod.hidden = false;
                return prod;
            });

            if (offeredProducts) {

                offeredProducts.map(function (prod, i) {
                    prod.formattedStartDate = CommonState.formatDate(prod.contractStartDate, 'MM-DD-YYYY');
                    prod.formattedEndDate = CommonState.formatDate(prod.contractEndDate, 'MM-DD-YYYY');
                    prod.docTypes = [...new Set(prod.productDocuments.map(p => p.documentTypeID))];
                    prod.offerCode = offerCode;
                    prod.offeredByCode = true;
                    prod.isFeatured = false;

                    renewalOffer.products.unshift(prod);
                });

                productOfferSearchStatus = state.common.constants.searchStatus.FOUND;
            }

            dispatch({ type: 'RESPONSE_RENEWAL_OFFER_PROD', renewalOffer, productOfferSearchStatus });

        }).catch(function (error) {

            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    renewService: (araProduct) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        var offerCode = araProduct.offerCode ? araProduct.offerCode : '';

        let req = {};
        req.productID = araProduct.productID;
        req.offerCode = offerCode;
        req.AccountID = state.common.accountID;
        req.accountServicePointID = state.common.accountServicePointID;
        req.ipAddress = state.common.ip;
        req.languageID = state.common.langID;
        req.renewalSourceType = 2;//myAccount

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/ServiceRenewal`, { headers: headers, method: 'POST', body: JSON.stringify(req), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (!data.isError) {
                    dispatch({ type: 'RESPONSE_SERVICE_RENEWAL', twoFactorAuthentication: { isResend: true } });
                } else {
                    dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: 'twoFactorAuthentication.cantGenerateANewCode' });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_ACCOUNT_START' });
    },

    getWebProductInformation: (address) => (dispatch, getState) => {


        const state = getState();
        let headers = CommonState.headers;

        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        const { distributionPoint, distributionPoints } = state.service;
        const { distributionServiceProviderID } = distributionPoint ? distributionPoint :
                                                                      distributionPoints ? distributionPoints[0] : undefined; 

        dispatch({ type: 'CLEAN_SELECTED_WEBPRODUCT' });

        if (!distributionServiceProviderID) { return; } 

        let request = {};
        request.accountID = state.common.accountID;
        request.zipCode = address.zipCode;
        request.distributionServiceProviderID = distributionServiceProviderID;
        request.companyID = state.common.companyID;
        request.languageID = state.common.langID;
        // request.active = true;
        // request.effectiveStartEndDate = true;

        let queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

        fetch(`${state.common.serviceUrl}/WebProductsForAddLocation?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                const { webProducts } = data; 
                
                if (!webProducts || webProducts.length === 0) {
                    dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: T.translate("addLocation.submitAddLocationError", { phoneNumber: state.common.systemProperties.customerServicePhone, emailSupport: state.common.systemProperties.customerServiceEmail }) });
                    return;
                } else {
                    dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: '' });
                }

                let selectedWebProduct = { ...webProducts[0] }; 
                const { productDocuments } = selectedWebProduct;

                if (productDocuments && productDocuments.length > 0) {

                    const documents = productDocuments.filter(x => (x.distributionServiceProviderID === 99
                                                                 || x.distributionServiceProviderID === distributionServiceProviderID)
                                                                 && x.productDocumentLanguageID == state.common.langID);

                    selectedWebProduct = { ...selectedWebProduct, productDocuments: documents };
                }

                dispatch({ type: 'SET_SELECTED_WEBPRODUCT', selectedWebProduct });
            })
            .catch(function (error) {
                dispatch({ type: 'SET_ACCOUNT_ERROR', serviceErrorMessage: error.message });
            });
    },
    setMilitaryDocument: (file) => (dispatch, getState) => {
        dispatch({ type: 'SET_MILITARY_ID_DOCUMENT_FILE', militaryAccountDocumentFile: file });
    },
    setReferral: (file) => (dispatch, getState) => {
        dispatch({ type: 'SET_REFERRAL_NAME', referralName: file });
    }
};

var removeRememberMe = function () {
    const cookies = new Cookies();
    cookies.remove('618e274e-79a9-4cef-aa4e-4ecbe0f492c3');
};

var clearAccountState = function (dispatch, getState, options = undefined) {
    dispatch({ type: 'CLEAR_ACCOUNT_STATE' });
    CommonState.secureStorage.clear();

    if (options && !options.skipRedirect) {
        history.push('/');
        window.location.replace('/');
    }
};

var getAccountBanners = function (dispatch, getState) {

    const state = getState();

    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    let fetchAccountBanners = fetch(`${state.common.serviceUrl}/AccountBanners?AccountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse);

    fetchAccountBanners.then(accountBanners => {
        let accountHeaderBanners = [];
        let accountMarketingBanners = [];

        accountBanners.map(function (banner, i) {
            if (banner.bannerTemplatePositionID === 1) {
                accountHeaderBanners.push(banner);
            } else if (banner.bannerTemplatePositionID === 2) {
                accountMarketingBanners.push(banner);
            }

        });

        dispatch({ type: 'SET_ACCOUNT_BANNERS', accountHeaderBanners: accountHeaderBanners, accountMarketingBanners: accountMarketingBanners });
    }).catch(function (error) {
        dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
    });
    dispatch({ type: 'REQUEST_ACCOUNT_START' });
};

var getSuggestedPaymentObject = function (dispatch, getState) {

    const state = getState();
    let headers = CommonState.headers;
    let suggestedPaymentObject = null;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');
    let suggestedPaymentRequest = fetch(`${state.common.serviceUrl}/SuggestedPayment?AccountID=${state.common.accountID}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse);
    suggestedPaymentRequest.then(suggestedPaymentObject => {
        dispatch({ type: 'SET_SUGGESTED_PAYMENT_OBJECT', suggestedPaymentObject: suggestedPaymentObject });
    }).catch(function (error) {
        dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
    });

    dispatch({ type: 'REQUEST_ACCOUNT_START' });
};

var getAccountActivityMonthly = function (dispatch, getState, meterReadTypeID) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    let accountActivityTypeID = 10;
    let from = new Date();
    let to = new Date();

    from.setHours(0, 0, 0, 0);
    from.setDate(1);
    from = CommonState.addYearsToDate(from, -2);

    var fromString = CommonState.formatDate(from, 'MM/DD/YYYY');
    var toString = CommonState.formatDate(to, 'MM/DD/YYYY');

    var request = { accountID: state.common.accountID, accountServicePointID: state.common.accountServicePointID, meterReadTypeID, fromDate: fromString, toDate: toString, accountActivityTypeID: accountActivityTypeID };

    if (!request.accountID || !request.accountServicePointID) {
        return;
    }

    var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

    fetch(`${state.common.serviceUrl}/AccountActivity?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {

            if (data) {

                //electricity
                let activityMonthlyElectricityCategories = [];
                let activityMonthlyElectricitySeries = [];
                let activityMonthlyElectricitySeries2 = [];
                //water
                let activityMonthlyWaterCategories = [];
                let activityMonthlyWaterSeries = [];
                let activityMonthlyWaterSeries2 = [];

                let activityElectricityMonthlyStatistics = {};
                let activityWaterMonthlyStatistics = {};

                if (data.reads && data.reads.length) {
                    let consumptionNumberOfDecimalsToShow = '';
                    if (!isNaN(parseInt(state.common.systemProperties.consumptionDecimalNumber))) {
                        consumptionNumberOfDecimalsToShow = state.common.systemProperties.consumptionDecimalNumber;
                    }

                    //electricity
                    let reads = data.reads.filter(x => x.commodityID === 1);

                    activityElectricityMonthlyStatistics = getActivityStatistics(reads);

                    activityMonthlyElectricityCategories = reads.map(function (obj) {
                        return CommonState.formatDate(obj.fromDate, 'MMM YYYY');
                    });

                    activityMonthlyElectricitySeries = reads.map(function (obj) {
                        return setDecimalConsumptionPart(obj, consumptionNumberOfDecimalsToShow);
                    });

                    activityMonthlyElectricitySeries2 = reads.map(function (obj) {
                        return obj.totalCharges;
                    });

                    //water
                    reads = data.reads.filter(x => x.commodityID === 3);

                    activityWaterMonthlyStatistics = getActivityStatistics(reads);

                    activityMonthlyWaterCategories = reads.map(function (obj) {
                        return CommonState.formatDate(obj.fromDate, 'MMM YYYY');
                    });

                    activityMonthlyWaterSeries = reads.map(function (obj) {
                        return setDecimalConsumptionPart(obj, consumptionNumberOfDecimalsToShow);
                    });

                    activityMonthlyWaterSeries2 = reads.map(function (obj) {
                        return obj.totalCharges;
                    });

                } else {
                    data.reads = [];
                }

                dispatch({ type: 'SET_ACCOUNT_ACTIVITY_MONTHLY_ELECTRICITY', activityMonthlyElectricityCategories, activityMonthlyElectricitySeries, activityMonthlyElectricitySeries2, activityElectricityMonthlyStatistics });
                dispatch({ type: 'SET_ACCOUNT_ACTIVITY_MONTHLY_WATER', activityMonthlyWaterCategories, activityMonthlyWaterSeries, activityMonthlyWaterSeries2, activityWaterMonthlyStatistics });

            }

        })
        .catch(function (error) {
            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_ACCOUNT_START' });
};

var getAccountActivityDaily = function (dispatch, getState, meterReadTypeID) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    let accountActivityTypeID = 11;
    let from = new Date();
    let to = new Date();

    from.setHours(0, 0, 0, 0);
    from = CommonState.addMonthsToDate(from, -2);

    var fromString = CommonState.formatDate(from, 'MM/DD/YYYY');
    var toString = CommonState.formatDate(to, 'MM/DD/YYYY');

    var request = { accountID: state.common.accountID, accountServicePointID: state.common.accountServicePointID, meterReadTypeID, fromDate: fromString, toDate: toString, accountActivityTypeID: accountActivityTypeID };

    var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

    fetch(`${state.common.serviceUrl}/AccountActivity?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {

            if (data) {

                //electricity
                let activityDailyElectricityCategories = [];
                let activityDailyElectricitySeries = [];
                let activityDailyElectricitySeries2 = [];
                //water
                let activityDailyWaterCategories = [];
                let activityDailyWaterSeries = [];
                let activityDailyWaterSeries2 = [];

                let activityElectricityDailyStatistics = {};
                let activityWaterDailyStatistics = {};

                if (data.reads) {
                    let consumptionNumberOfDecimalsToShow = '';
                    if (!isNaN(parseInt(state.common.systemProperties.consumptionDecimalNumber))) {
                        consumptionNumberOfDecimalsToShow = state.common.systemProperties.consumptionDecimalNumber;
                    }
                    //electricity
                    let reads = data.reads.filter(x => x.commodityID === 1);

                    activityElectricityDailyStatistics = getActivityStatistics(reads, false);

                    activityDailyElectricityCategories = reads.map(function (obj) {
                        return CommonState.formatDate(obj.fromDate, 'MM/DD/YY');
                    });

                    activityDailyElectricitySeries = reads.map(function (obj) {
                        let consumption = setDecimalConsumptionPart(obj, consumptionNumberOfDecimalsToShow);

                        let act = { y: consumption };

                        if (obj.meterReadStatusID === 5 && obj.isEstimated) {
                            act.color = 'url(#billed-estimated-pattern)';
                        } else if (obj.meterReadStatusID !== 5) {
                            if (obj.isEstimated) {
                                act.color = 'url(#unbilled-estimated-pattern)';
                            } else {
                                act.color = '#ecb67c';
                            }
                        }

                        return act;
                    });

                    activityDailyElectricitySeries2 = reads.map(function (obj) {
                        return obj.totalCharges;
                    });

                    //water
                    reads = data.reads.filter(x => x.commodityID === 3);

                    activityWaterDailyStatistics = getActivityStatistics(reads, false);

                    activityDailyWaterCategories = reads.map(function (obj) {
                        return CommonState.formatDate(obj.fromDate, 'MM/DD/YY');
                    });

                    activityDailyWaterSeries = reads.map(function (obj) {
                        let consumption = setDecimalConsumptionPart(obj, consumptionNumberOfDecimalsToShow);

                        if (obj.meterReadStatusID === 5) {
                            return { y: consumption };
                        } else {
                            return { y: consumption, color: '#ecb67c' };
                        }
                    });

                    activityDailyWaterSeries2 = reads.map(function (obj) {
                        return obj.totalCharges;
                    });


                } else {
                    data.reads = [];
                }

                dispatch({ type: 'SET_ACCOUNT_ACTIVITY_DAILY_ELECTRICITY', activityDailyElectricityCategories, activityDailyElectricitySeries, activityDailyElectricitySeries2, activityElectricityDailyStatistics });
                dispatch({ type: 'SET_ACCOUNT_ACTIVITY_DAILY_WATER', activityDailyWaterCategories, activityDailyWaterSeries, activityDailyWaterSeries2, activityWaterDailyStatistics });

            }

        })
        .catch(function (error) {
            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_ACCOUNT_START' });
};

var getAccountActivityInterval = function (dispatch, getState, date, meterReadTypeID) {

    const state = getState();
    let headers = CommonState.headers;
    headers.Authorization = CommonState.secureStorage.getItem('privateToken');

    let accountActivityTypeID = 12;
    var fromString = date;
    var toString = date;

    var request = { accountID: state.common.accountID, accountServicePointID: state.common.accountServicePointID, meterReadTypeID, fromDate: fromString, toDate: toString, accountActivityTypeID: accountActivityTypeID };

    var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

    fetch(`${state.common.serviceUrl}/AccountActivity?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {

            if (data && data.reads) {

                //electricity
                let reads = data.reads.filter(x => x.commodityID === 1);

                let activityIntervalElectricityCategories = reads.map(function (obj) {
                    return CommonState.formatDate(obj.fromDate, 'LT');
                });

                let activityIntervalElectricitySeries = reads.map(function (obj) {

                    let act = { y: obj.totalConsumption };

                    if (obj.isEstimated) {
                        act.color = 'url(#billed-estimated-pattern)';
                    }

                    return act;
                });

                let activityIntervalElectricitySeries2 = reads.map(function (obj) {
                    return obj.totalCharges;
                });

                //water
                reads = data.reads.filter(x => x.commodityID === 3);

                let activityIntervalWaterCategories = reads.map(function (obj) {
                    return CommonState.formatDate(obj.fromDate, 'LT');
                });

                let activityIntervalWaterSeries = reads.map(function (obj) {
                    return obj.totalConsumption;
                });

                let activityIntervalWaterSeries2 = reads.map(function (obj) {
                    return obj.totalCharges;
                });

                dispatch({ type: 'SET_ACCOUNT_ACTIVITY_INTERVAL_ELECTRICITY', activityIntervalElectricityCategories, activityIntervalElectricitySeries, activityIntervalElectricitySeries2 });
                dispatch({ type: 'SET_ACCOUNT_ACTIVITY_INTERVAL_WATER', activityIntervalWaterCategories, activityIntervalWaterSeries, activityIntervalWaterSeries2 });

            }

        })
        .catch(function (error) {
            dispatch({ type: 'SET_ACCOUNT_ERROR', accountErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_ACCOUNT_START' });
};

var getActivityStatistics = function (meterReads, isPerMonth = true) {
    var perMonth = {};
    var perDay = {};
    var i = 0;

    if (isPerMonth) {

        //statistics per month
        let currentYearMonthDateStart = new Date(new Date().setHours(0, 0, 0, 0));
        currentYearMonthDateStart.setMonth(currentYearMonthDateStart.getMonth() - 1);
        currentYearMonthDateStart.setDate(1);

        let currentYearMonthDateEnd = new Date(currentYearMonthDateStart);
        currentYearMonthDateEnd.setMonth(currentYearMonthDateEnd.getMonth() + 1);

        const currentYearMonthReads = meterReads.filter(x => new Date(x.fromDate) >= currentYearMonthDateStart && new Date(x.fromDate) < currentYearMonthDateEnd);

        let previousYearMonthDateStart = new Date(currentYearMonthDateStart);
        previousYearMonthDateStart.setFullYear(currentYearMonthDateStart.getFullYear() - 1);

        const previousYearMonthDateEnd = new Date(previousYearMonthDateStart);
        previousYearMonthDateEnd.setMonth(previousYearMonthDateEnd.getMonth() + 1);

        const lastYearMonthReads = meterReads.filter(x => new Date(x.fromDate) >= previousYearMonthDateStart && new Date(x.fromDate) < previousYearMonthDateEnd);

        let previousMonthDateStart = new Date(currentYearMonthDateStart);
        previousMonthDateStart.setMonth(previousMonthDateStart.getMonth() - 1);
        previousMonthDateStart.setDate(1);

        var previousMonthReads = meterReads.filter(x => new Date(x.fromDate) >= previousMonthDateStart && new Date(x.fromDate) < currentYearMonthDateStart);

        let currentYearMonthAmount = 0;
        let currentYearMonthAmountAverage = 0;
        let currentYearMonthDaysCount = 0;
        let lastYearMonthAmount = 0;
        let lastYearMonthAmountAverage = 0;
        let lastYearMonthDaysCount = 0;
        let previousMonthAmount = 0;
        let previousMonthAmountAverage = 0;
        let previousMonthDaysCount = 0;

        for (i = 0; i < currentYearMonthReads.length; i++) {
            currentYearMonthAmount += currentYearMonthReads[i].totalCharges;
            currentYearMonthDaysCount += currentYearMonthReads[i].intervalLength;
        }

        if (currentYearMonthDaysCount) {
            currentYearMonthAmountAverage = currentYearMonthAmount / currentYearMonthDaysCount;
        }

        for (i = 0; i < lastYearMonthReads.length; i++) {
            lastYearMonthAmount += lastYearMonthReads[i].totalCharges;
            lastYearMonthDaysCount += lastYearMonthReads[i].intervalLength;
        }

        if (lastYearMonthDaysCount) {
            lastYearMonthAmountAverage = lastYearMonthAmount / lastYearMonthDaysCount;
        }

        for (i = 0; i < previousMonthReads.length; i++) {
            previousMonthAmount += previousMonthReads[i].totalCharges;
            previousMonthDaysCount += previousMonthReads[i].intervalLength;
        }

        if (previousMonthDaysCount) {
            previousMonthAmountAverage = previousMonthAmount / previousMonthDaysCount;
        }

        let previousMonthPercentageDiff = 0;
        let previousYearMonthPercentageDiff = 0;

        if (previousMonthAmountAverage) {
            previousMonthPercentageDiff = 100 - currentYearMonthAmountAverage * 100 / previousMonthAmountAverage;
        }

        if (lastYearMonthAmountAverage) {
            previousYearMonthPercentageDiff = 100 - currentYearMonthAmountAverage * 100 / lastYearMonthAmountAverage;
        }

        var previousMonthAmountDiff = currentYearMonthAmountAverage - previousMonthAmountAverage;
        var previousYearMonthAmountDiff = currentYearMonthAmountAverage - lastYearMonthAmountAverage;

        perMonth = {
            date: currentYearMonthDateStart,
            previousMonthDate: previousMonthDateStart,
            currentYearMonthAmount: currentYearMonthAmount.toFixed(2),
            previousMonthAmount: previousMonthAmount.toFixed(2),
            lastYearMonthAmount: lastYearMonthAmount.toFixed(2),
            previousMonthPercentageDiff: Math.abs(previousMonthPercentageDiff.toFixed(2)),
            previousMonthAmountDiff: previousMonthAmountDiff.toFixed(2),
            currentYearMonthDaysCount: currentYearMonthDaysCount,
            previousMonthDaysCount: previousMonthDaysCount,
            previousYearMonthPercentageDiff: Math.abs(previousYearMonthPercentageDiff.toFixed(2)),
            previousYearMonthAmountDiff: previousYearMonthAmountDiff.toFixed(2),
            previousYearMonthAmountDiffAbs: Math.abs(previousYearMonthAmountDiff.toFixed(2)),
            isPreviousMonthPesent: previousMonthReads.length > 0,
            isPreviousYearMonthPesent: lastYearMonthReads.length > 0
        };

    } else {

        //daily statistics
        let recentWeekDateEnd = new Date(Math.max.apply(null, meterReads.map(function (mr) {
            return new Date(mr.fromDate);
        })));

        let recentWeekDateStart = new Date();

        if (recentWeekDateEnd) {
            recentWeekDateEnd.setDate(recentWeekDateEnd.getDate() + 1);

            recentWeekDateStart = new Date(recentWeekDateEnd);
            recentWeekDateStart.setDate(recentWeekDateStart.getDate() - 7);

        } else {
            recentWeekDateStart = new Date(new Date().setHours(0, 0, 0, 0));
            recentWeekDateStart.setDate(recentWeekDateStart.getDate() - 9);

            recentWeekDateEnd = new Date(recentWeekDateStart);
            recentWeekDateEnd.setDate(recentWeekDateEnd.getDate() + 8);
        }

        const recentWeekReads = meterReads.filter(x => new Date(x.fromDate) >= recentWeekDateStart && new Date(x.fromDate) < recentWeekDateEnd);

        let previousWeekDateStart = new Date(recentWeekDateStart);
        previousWeekDateStart.setDate(previousWeekDateStart.getDate() - 7);

        const previousWeekReads = meterReads.filter(x => new Date(x.fromDate) >= previousWeekDateStart && new Date(x.fromDate) < recentWeekDateStart);

        let currentWeekAmount = 0;
        let currentWeekConsumption = 0;
        let currentWeekDaysCount = 0;

        let dailyAmount = 0;
        let dailyConsumption = 0;

        for (i = 0; i < recentWeekReads.length; i++) {
            currentWeekAmount += recentWeekReads[i].totalCharges;
            currentWeekConsumption += recentWeekReads[i].totalConsumption;
            currentWeekDaysCount++;
        }

        if (currentWeekDaysCount) {
            dailyAmount = currentWeekAmount / currentWeekDaysCount;
            dailyConsumption = currentWeekConsumption / currentWeekDaysCount;
        }

        let previousWeekAmount = 0;
        let previousWeekConsumption = 0;
        let previousWeekDaysCount = 0;

        let previousDailyAmount = 0;
        let previousDailyConsumption = 0;

        for (i = 0; i < previousWeekReads.length; i++) {
            previousWeekConsumption += previousWeekReads[i].totalConsumption;
            previousWeekAmount += previousWeekReads[i].totalCharges;
            previousWeekDaysCount++;
        }

        if (previousWeekDaysCount) {
            previousDailyAmount = previousWeekAmount / previousWeekDaysCount;
            previousDailyConsumption = previousWeekConsumption / previousWeekDaysCount;
        }

        let previousWeekPercentageDiff = 0;
        let previousWeekAmountDiff = 0;

        if (previousDailyAmount) {
            previousWeekPercentageDiff = 100 - dailyAmount * 100 / previousDailyAmount;
            previousWeekAmountDiff = currentWeekAmount - previousWeekAmount;
        }

        recentWeekDateEnd.setDate(recentWeekDateEnd.getDate() - 1);

        perDay = {
            startDate: recentWeekDateStart,
            endDate: recentWeekDateEnd,
            dailyAmount: Math.abs(dailyAmount.toFixed(2)),
            dailyConsumption: Math.abs(dailyConsumption.toFixed(2)),
            previousDailyAmount: Math.abs(previousDailyAmount.toFixed(2)),
            previousDailyConsumption: Math.abs(previousDailyConsumption.toFixed(2)),
            previousWeekPercentageDiff: Math.abs(previousWeekPercentageDiff.toFixed(2)),
            previousWeekAmountDiff: previousWeekAmountDiff.toFixed(2),
            isPreviousWeekPesent: previousWeekReads.length > 0
        };
    }


    const stats = {
        isUsagePresent: meterReads.length > 0,
        perMonth: perMonth,
        perDay: perDay
    };

    return stats;
};

var setDecimalConsumptionPart = function(obj, consumptionNumberOfDecimalsToShow) {
    let consumption = obj.totalConsumption;
    if (isNaN(consumption)) {
        consumption = 0;
    } else {
        consumption = (obj.totalConsumption.trim == '' || consumptionNumberOfDecimalsToShow == '') ? obj.totalConsumption
            : parseFloat(obj.totalConsumption.toFixed(consumptionNumberOfDecimalsToShow));
    }
    return consumption;
}

Date.prototype.daysInMonth = function () {
    var d = new Date(this.getFullYear(), this.getMonth() + 1, 0);
    return d.getDate();
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case 'REQUEST_ACCOUNT_START':
            return { ...state, accountLoading: true, accountErrorMessage: '', productOfferSearchStatus: 0 };
        case 'SET_ACCOUNT_ERROR':
            return { ...state, accountErrorMessage: action.accountErrorMessage, accountLoading: false, accountSourceError: action.accountSourceError };
        case 'REQUEST_ACCOUNT_END':
            return { ...state, accountLoading: false };
        case 'REQUEST_TFA_START':
            return { ...state, accountLoading: true, accountErrorMessage: '', twoFactorAuthentication: { isResend: false } };
        case 'REQUEST_TFA_END':
            return { ...state, accountLoading: false, twoFactorAuthentication: action.twoFactorAuthentication };
        case 'REQUEST_AUTOLOGIN_END':
            return { ...state, accountLoading: false };
        case 'RESPONSE_MONTHLY_STATEMENTS':
            return { ...state, accountLoading: false, accountStatements: action.accountStatements, years: action.years };
        case 'RESPONSE_TRANSACTION_HISTORY':
            return { ...state, accountLoading: false, transactionsHistory: action.transactionsHistory, years: action.years };
        case 'SET_TFA_BASIC':
            return { ...state, accountLoading: false, accountPersons: action.accountPersons, username: action.username, password: action.password };
        case 'SET_ACCOUNT_VERIFICATION_REQUIRED':
            return { ...state, accountLoading: false, isAccountVerificationRequired: action.isAccountVerificationRequired, accountPersons: action.accountPersons };
        case 'SET_USERNAME_UPDATE_REQUIRED':
            return { ...state, accountLoading: false, accountPersons: action.accountPersons, password: action.password };
        case 'RESPONSE_PLANS_&_DOCUMENTS':
            return { ...state, accountLoading: false, serviceAgreement: action.serviceAgreement, accountDocuments: action.accountDocuments };
        case 'RESPONSE_MEMBERS':
            return { ...state, accountLoading: false, member: action.member };
        case 'RESPONSE_LOCATION_PROGRAMS':
            return { ...state, accountLoading: false, memberProgram: action.memberProgram };
        case 'RESPONSE_LOCATION_PROGRAMS_ERROR':
            return { ...state, accountLoading: false, isError: action.isError, memberProgramError: action.memberProgramError };
        case 'RESPONSE_LOCATION_PROGRAMS_SUCCESS':
            return { ...state, accountLoading: false, isSuccess: action.isSuccess };
        case 'RESPONSE_RENEWAL_OFFER_ACTION':
            return { ...state, accountLoading: false, renewalOffer: action.renewalOffer };
        case 'RESPONSE_RENEWAL_OFFER_PROD':
            return { ...state, accountLoading: false, renewalOffer: action.renewalOffer, productOfferSearchStatus: action.productOfferSearchStatus };
        case 'RESPONSE_SERVICE_RENEWAL':
            return { ...state, accountLoading: false, serviceRenewed: true };
        case 'SET_AUTOLOGIN_TOKEN':
            return { ...state, accountLoading: false };
        case 'SET_ACCOUNT_ACTIVITY_MONTHLY_ELECTRICITY':
            return { ...state, accountLoading: false, activityMonthlyElectricityCategories: action.activityMonthlyElectricityCategories, activityMonthlyElectricitySeries: action.activityMonthlyElectricitySeries, activityMonthlyElectricitySeries2: action.activityMonthlyElectricitySeries2, activityElectricityMonthlyStatistics: action.activityElectricityMonthlyStatistics };
        case 'SET_ACCOUNT_ACTIVITY_MONTHLY_WATER':
            return { ...state, accountLoading: false, activityMonthlyWaterCategories: action.activityMonthlyWaterCategories, activityMonthlyWaterSeries: action.activityMonthlyWaterSeries, activityMonthlyWaterSeries2: action.activityMonthlyWaterSeries2, activityWaterMonthlyStatistics: action.activityWaterMonthlyStatistics };
        case 'SET_ACCOUNT_ACTIVITY_DAILY_ELECTRICITY':
            return { ...state, accountLoading: false, activityDailyElectricityCategories: action.activityDailyElectricityCategories, activityDailyElectricitySeries: action.activityDailyElectricitySeries, activityDailyElectricitySeries2: action.activityDailyElectricitySeries2, activityElectricityDailyStatistics: action.activityElectricityDailyStatistics };
        case 'SET_ACCOUNT_ACTIVITY_DAILY_WATER':
            return { ...state, accountLoading: false, activityDailyWaterCategories: action.activityDailyWaterCategories, activityDailyWaterSeries: action.activityDailyWaterSeries, activityDailyWaterSeries2: action.activityDailyWaterSeries2, activityWaterDailyStatistics: action.activityWaterDailyStatistics };
        case 'SET_ACCOUNT_ACTIVITY_INTERVAL_ELECTRICITY':
            return { ...state, accountLoading: false, activityIntervalElectricityCategories: action.activityIntervalElectricityCategories, activityIntervalElectricitySeries: action.activityIntervalElectricitySeries, activityIntervalElectricitySeries2: action.activityIntervalElectricitySeries2 };
        case 'SET_ACCOUNT_ACTIVITY_INTERVAL_WATER':
            return { ...state, accountLoading: false, activityIntervalWaterCategories: action.activityIntervalWaterCategories, activityIntervalWaterSeries: action.activityIntervalWaterSeries, activityIntervalWaterSeries2: action.activityIntervalWaterSeries2 };
        case 'CLEAR_ACCOUNT_STATE':
            return initialState;
        case 'SET_ACCOUNT_BANNERS':
            return { ...state, accountLoading: false, accountHeaderBanners: action.accountHeaderBanners, accountMarketingBanners: action.accountMarketingBanners };
        case 'SET_SUGGESTED_PAYMENT_OBJECT':
            return { ...state, accountLoading: false, suggestedPaymentObject: action.suggestedPaymentObject };
        case 'SET_SELECTED_WEBPRODUCT':
            return { ...state, accountLoading: false, selectedWebProduct: action.selectedWebProduct };
        case 'CLEAN_SELECTED_WEBPRODUCT':
            return { ...state, selectedWebProduct: '' };
        case 'SET_MILITARY_ID_DOCUMENT_FILE':
            return { ...state, militaryAccountDocumentFile: action.militaryAccountDocumentFile };
        case 'SET_REFERRAL_NAME':
            return { ...state, referralName: action.referralName };
        case 'RECEIVE_PROGRAMS':
            return { ...state, addOnCharges: action.addOnCharges, utilityPrograms: action.utilityPrograms }
        case 'RECEIVE_PROMOTION_PRODUCT':
            return { ...state, promotions: action.promotions }
        default:
            return state;
    }
};