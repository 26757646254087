import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as PaymentStore from '../../store/Payment';
import PaymentSidebar from './PaymentSidebar';
import T from 'i18n-react';
import Select from 'react-select';
import ExternalLink from '../shared/ExternalLink';
import LoadingOverlay from '../shared/LoadingOverlay';
import LoadingButton from '../shared/LoadingButton';
import MessageError from '../shared/MessageError';
import ConfirmModal from '../shared/ConfirmModal';
import { PaymentAccountOption } from './PaymentAccountOption';
import { PaymentAccountSingleValue } from './PaymentAccountSingleValue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Scroll = require('react-scroll');
const paymentIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/payment-icon.png`);
const autopayIcon = require(`../../images/${process.env.REACT_APP_CLIENT}/autopay-icon.png`);
const transctIcon = require('../../images/transct-icon.png');

class AutoPay extends Component {

    constructor(props) {
        super(props);

        this.state = {
            threshold: '1',
            autoPayAmount: '',
            balanceThresholdAmount: '',
            daysRemainingOption: '2',
            infoCreditCardFeeModalVisible: false,
            paymentChargeFee: {}
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('autopay.title');

        if (this.props.account.accountNumber) {
            this.props.autoPayInit();
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.autoPayInit();
        }

        this.setPaymentChargeFeeConfig();
    }

    onChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    onSubmit = (e) => {
        this.props.saveAutoPayment(this.state);
    }

    changeAutoPay = (e) => {

        let autoPay = {};
        autoPay.autoPayAmount = this.props.accountAutoPayment.autoPayAmount;

        if (this.props.accountAutoPayment.systemEventTriggerID === 261) {
            autoPay.threshold = '1';
            autoPay.balanceThresholdAmount = this.props.accountAutoPayment.balanceThreshold;
        } else if (this.props.accountAutoPayment.systemEventTriggerID === 60) {
            autoPay.threshold = '2';
            autoPay.daysRemainingOption = '10';
        } else if (this.props.accountAutoPayment.systemEventTriggerID === 70) {
            autoPay.threshold = '2';
            autoPay.daysRemainingOption = '7';
        } else if (this.props.accountAutoPayment.systemEventTriggerID === 80) {
            autoPay.threshold = '2';
            autoPay.daysRemainingOption = '5';
        } else if (this.props.accountAutoPayment.systemEventTriggerID === 90) {
            autoPay.threshold = '2';
            autoPay.daysRemainingOption = '3';
        } else if (this.props.accountAutoPayment.systemEventTriggerID === 100) {
            autoPay.threshold = '2';
            autoPay.daysRemainingOption = '2';
        }

        this.setState({ threshold: autoPay.threshold, autoPayAmount: autoPay.autoPayAmount, balanceThresholdAmount: autoPay.balanceThresholdAmount, daysRemainingOption: autoPay.daysRemainingOption });

        this.props.changePaymentAccountByID(this.props.accountAutoPayment.paymentAccountID);

        this.props.toggleShowAutoPayForm();
    }

    toggleShowAutoPayForm = (e) => {
        this.props.toggleShowAutoPayForm();
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
    }

    stopAutoPay = (e) => {
        this.props.toggleStopAutoPayConfirmModal();
    }

    handleChange = (selectedOption) => {
        this.props.changePaymentAccount(selectedOption);
    }

    goToPaymentMethod = () => {
        this.props.goToPaymentMethod(true);
    }

    open = () => {
        this.setState({ infoCreditCardFeeModalVisible: true });
    }

    close = () => {
        this.setState({ infoCreditCardFeeModalVisible: false });
    }

    setPaymentChargeFeeConfig = () => {

        if (this.props.paymentAccount && this.props.paymentAccount.paymentAccountTypeName)
        {
            let selectedPaymentType = this.props.paymentAccount.paymentAccountTypeName.toLowerCase();

            if (selectedPaymentType === this.props.constants.paymentTypes.DEBIT ||
                selectedPaymentType === this.props.constants.paymentTypes.CREDITCARD) {

                let paymentChargeFeeCC = {
                    paymentMethod: T.translate('suggestedPayment.paymentsIncursCreditCard'),
                    chargeFee: this.props.systemProperties.paymentusCreditCardCharge
                }

                this.setState({ paymentChargeFee: paymentChargeFeeCC });
            }
            else {

                let paymentChargeFeeEcheck = {
                    paymentMethod: T.translate('suggestedPayment.paymentsIncurseCheck'),
                    chargeFee: this.props.systemProperties.paymentusECheckCharge
                }

                this.setState({ paymentChargeFee: paymentChargeFeeEcheck });
            }
        }
    }

    render() {
        return (
            <div className="payment-section">

                <div className="left-sidebar">
                    <div className="p-main">

                        <LoadingOverlay loading={this.props.paymentLoading} />

                        {this.props.accountAutoPayment.accountAutoPayID > 0 && !this.props.showAutoPayForm && <div>
                            <div className="page-header">
                                <div className="page-title">{T.translate('autopay.pageTitle')}</div>
                            </div>

                            <div className="card bl-blue mb-4 mb-sm-5">
                                <div className="card-body pay-echeck align-items-center">
                                    <img src={autopayIcon} alt="autopay" />
                                    <div>
                                        <h6 className="card-title mt-0 mb-0">{T.translate('autopay.youAreCurrentlyEnrolledInAutoPay')}</h6>
                                    </div>
                                </div>
                            </div>

                            <h6 className="card-title">{T.translate('autopay.autoPaySettings')}</h6>
                            <div className="gray-table clearfix">
                                <dl className="gray-table-dt">
                                    <dt className="gray-table-dt-label">{T.translate('autopay.accountName')}</dt>
                                    <dd className="gray-table-dt-value">{this.props.accountAutoPayment.paymentAccountName} {T.translate('autopay.endingIn')} {this.props.accountAutoPayment.accountNumber ? this.props.accountAutoPayment.accountNumber.substr(this.props.accountAutoPayment.accountNumber.length - 4) : ''}</dd>
                                </dl>
                                <dl className="gray-table-dt">
                                    <dt className="gray-table-dt-label">{T.translate('autopay.amount')}</dt>
                                    <dd className="gray-table-dt-value">{this.props.account.accountBillingTypeID === 1 ? ('$' + CommonStore.formatDecimal(this.props.accountAutoPayment.autoPayAmount)) : T.translate('autopay.billDueAmount')}</dd>
                                </dl>
                                <dl className="gray-table-dt">
                                    <dt className="gray-table-dt-label">{T.translate('autopay.rechargeTrigger')}</dt>
                                    <dd className="gray-table-dt-value">{this.props.accountAutoPayment.systemEventName}</dd>
                                </dl>
                            </div>

                            {this.props.showFeePaymentAdvertisement &&
                                <div className="card-flex mb-4 mt-5">
                                    <p>{T.translate('suggestedPayment.paymentsIncursMessageText', this.state.paymentChargeFee)}
                                        <FontAwesomeIcon className="card-flex-icon" icon={faInfoCircle} onClick={this.open} />
                                    </p>
                                </div>
                            }

                            {this.props.account.accountBillingTypeID === 1 && <a href="javascript:;" className="autopay-link" onClick={this.changeAutoPay}><img src={transctIcon} alt="change autopay" />{T.translate('autopay.changeAutoPay')}</a>}
                            <a href="javascript:;" className="autopay-link text-danger pull-right" onClick={this.stopAutoPay}>{T.translate('autopay.stopAutoPay')}</a>
                        </div>}

                        {(!this.props.accountAutoPayment.accountAutoPayID || this.props.showAutoPayForm) && <div>
                            <div className="page-header">
                                <div className="page-title">{T.translate('autopay.pageTitle')}</div>

                                <p className="lead font-weight-normal mt-2 text-dark">
                                    {T.translate('autopay.bySelectingAutopay')}
                                </p>

                            </div>

                            <div className="diverter" />

                            {this.props.showPaymentAccountCreated &&
                                <div className="alert alert-danger alert-primary" role="alert">
                                    {T.translate('autopay.yourPaymentAccountHasBeenCreated')}
                                </div>
                            }

                            <div className="row">
                                <div className="col-sm-6">
                                    <h6 className="card-title">{T.translate('autopay.paymentMethod')}</h6>
                                </div>
                                <div className="col-sm-6 text-left text-sm-right">
                                    <a href="javascript:;" onClick={this.goToPaymentMethod} className="payment-link mb-3 d-inline-block">
                                        <img src={paymentIcon} alt="payment" /><span>{T.translate('autopay.addPaymentMethod')}</span>
                                    </a>
                                </div>
                            </div>

                            {this.props.paymentAccounts && this.props.paymentAccounts.length > 0 &&
                                <Select
                                    value={this.props.paymentAccount}
                                    isDisabled={this.props.accountAutoPayment.accountAutoPayID > 0 && this.props.showAutoPayForm}
                                    onChange={this.handleChange}
                                    components={{ Option: PaymentAccountOption, SingleValue: PaymentAccountSingleValue }}
                                    options={this.props.paymentAccounts}
                                    isSearchable={false}
                                />
                            }

                            {(!this.props.paymentAccounts || this.props.paymentAccounts.length === 0) &&
                                <div className="alert alert-primary" role="alert">
                                    {T.translate('autopay.noPaymentMethodSelected')}
                                </div>
                            }

                            {this.props.paymentAccounts && this.props.paymentAccounts.length > 0 &&
                                <React.Fragment>
                                    {this.props.account.accountBillingTypeID === 1 && <div className="card mt-4 mt-sm-5 mb-4">
                                        <div className="card-body">
                                            <h6 className="card-title mt-2">{T.translate('autopay.autoPayThresholdSelectOption')}</h6>
                                            <div className="custom-control custom-radio mt-3 d-inline-block mr-4">
                                                <input type="radio" className="custom-control-input" value="1" id="thresholdBalance" name="threshold" checked={this.state.threshold === '1'} onChange={this.onChange} />
                                                <label className="custom-control-label" htmlFor="thresholdBalance">{T.translate('autopay.rechargeBalanceAmount')}</label>
                                            </div>
                                            <div className="custom-control custom-radio mt-3 d-inline-block mr-4">
                                                <input type="radio" className="custom-control-input" value="2" id="thresholdDays" name="threshold" checked={this.state.threshold === '2'} onChange={this.onChange} />
                                                <label className="custom-control-label" htmlFor="thresholdDays">{T.translate('autopay.rechargeDaysRemaining')}</label>
                                            </div>

                                            {this.state.threshold === '1' && <div className="recharge-balance mt-4">
                                                <div className="recomended-box">
                                                    <div className="recomended-first">
                                                        <div className="form-group">
                                                            <label>{T.translate('autopay.recommended')}</label>
                                                            <a href="javasacript:;" className="recomender-btn">$40</a>
                                                        </div>
                                                    </div>
                                                    <div className="or-txt">{T.translate('common.or')}</div>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-group credit-card-input">
                                                            <label htmlFor="balanceThresholdAmount">{T.translate('autopay.otherAmount')}</label>
                                                            <input type="text" className="form-control" id="balanceThresholdAmount" name="balanceThresholdAmount" value={this.state.balanceThresholdAmount} onChange={this.onChange} autoComplete="off" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}

                                            {this.state.threshold === '2' && <div className="recharge-days-remaning mt-4">
                                                <div className="form-group">
                                                    <label htmlFor="daysRemainingOption">{T.translate('autopay.selectNumberOfDays')}</label>
                                                    <select className="form-control pay-with-diffrent w-25" id="daysRemainingOption" name="daysRemainingOption" value={this.state.daysRemainingOption} onChange={this.onChange}>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="5">5</option>
                                                        <option value="7">7</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                            </div>}

                                        </div>
                                    </div>}

                                    <div className="card mt-4 mt-sm-5 mb-4">
                                        <div className="card-body">
                                            <h6 className="card-title mt-2">{T.translate('autopay.amountToCharge')}</h6>

                                            {this.props.account.accountBillingTypeID === 1 && <p className="lead">{T.translate('autopay.yourCardWillBeDebitedThisAmount')}</p>}
                                            {this.props.account.accountBillingTypeID !== 1 && <p className="lead">{T.translate('autopay.yourCardWillBeDebitedTheAmountOwed')}</p>}

                                            {this.props.account.accountBillingTypeID === 1 && <div className="recomended-box">
                                                <div className="recomended-first">
                                                    <div className="form-group">
                                                        <label>{T.translate('autopay.recommended')}</label>
                                                        <a href="javasacript:;" className="recomender-btn">$40</a>
                                                    </div>
                                                </div>
                                                <div className="or-txt">{T.translate('common.or')}</div>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-group credit-card-input">
                                                        <label htmlFor="autoPayAmount">{T.translate('autopay.otherAmount')}</label>
                                                        <input type="text" className="form-control" id="autoPayAmount" name="autoPayAmount" value={this.state.autoPayAmount} onChange={this.onChange} autoComplete="off" />
                                                    </div>
                                                </div>
                                            </div>}

                                        </div>
                                    </div>

                                    <MessageError>{this.props.paymentErrorMessage}</MessageError>

                                    {this.props.accountAutoPayment.accountAutoPayID > 0 &&
                                        <a href="javascript:;" onClick={this.toggleShowAutoPayForm} className="btn btn-outline-dark mr-3">{T.translate('common.cancel')}</a>
                                    }

                                    {this.props.showFeePaymentAdvertisement &&
                                        <div className="card-flex mb-4 mt-5">
                                            <p>{T.translate('suggestedPayment.paymentsIncursMessageText', this.state.paymentChargeFee)}
                                                <FontAwesomeIcon className="card-flex-icon" icon={faInfoCircle} onClick={this.open} />
                                            </p>
                                        </div>
                                    }

                                    <LoadingButton
                                        className="btn btn-primary"
                                        onClick={this.onSubmit}
                                        loading={this.props.paymentLoading ? 'true' : 'false'}>
                                        {T.translate('autopay.enrollInAutopay')}
                                    </LoadingButton>

                                    <p className="mt-4 mt-5">
                                        {T.translate('autopay.youAgreeToAndUnderstandThe')} <ExternalLink href={this.props.systemProperties.marketerTosUrl} className="a-link">{T.translate('autopay.termsOfService')}</ExternalLink> {T.translate('autopay.relatedToAutopay')}
                                    </p>
                                </React.Fragment>}
                        </div>}

                    </div>
                </div>

                <ConfirmModal
                    title={T.translate('autopay.confirmStopAutoPay')}
                    confirmButtonText={T.translate('autopay.stopAutoPay')}
                    open={this.props.showStopAutoPayConfirmModal}
                    onToggle={this.props.toggleStopAutoPayConfirmModal}
                    loading={this.props.paymentLoading}
                    onConfirm={this.props.deleteAutoPayment}>
                    {T.translate('autopay.areYouSureYouWantStopAutoPay')}
                </ConfirmModal>

                {this.props.showFeePaymentAdvertisement &&
                    <ConfirmModal
                        title={T.translate('suggestedPayment.paymentsIncursTitle', this.state.paymentChargeFee)}
                        open={this.state.infoCreditCardFeeModalVisible}
                        onToggle={this.close}>
                        <div>
                            <p>{T.translate('suggestedPayment.paymentsIncursBodyOne', this.state.paymentChargeFee)}</p>
                            <p>{T.translate('suggestedPayment.paymentsIncursBodyTwo')}</p>
                        </div>
                    </ConfirmModal>
                }

                <PaymentSidebar />
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common, ...state.payment }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators, ...PaymentStore.actionCreators }, dispatch)
)(AutoPay);
