import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import HighchartsPatternFill from 'highcharts-pattern-fill';
import drilldown from 'highcharts/modules/drilldown';
import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Animate from 'react-smooth';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import ExternalLink from '../shared/ExternalLink';
import LoadingOverlay from '../shared/LoadingOverlay';

drilldown(Highcharts);
HighchartsPatternFill(Highcharts);

const Scroll = require('react-scroll');
const plug = require(`../../images/${process.env.REACT_APP_CLIENT}/plug-icon.png`);
const plugWater = require(`../../images/${process.env.REACT_APP_CLIENT}/plug-icon-water.png`);
const refer = require('../../images/refer-a-friend.jpg');
const autopay = require('../../images/autopay.jpg');

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: '0',
            chartEnergyTypeID: '1',
            chartWaterTypeID: '1',
            intervalElectricity: false,
            intervalWater: false,
            intervalDateElectricity: '',
            intervalDateWater: '',
            calculationLegendText: '',
            bannersHeader: [],
            bannerMarketing: undefined,
            highchartsOptionDailyWithEnergySpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        },
                        {
                            id: 'unbilled-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: '#ecb67c',
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.energySpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)',
                    useHTML: true,
                    labelFormatter: function () {

                        if (this.name === T.translate('dashboard.kWhUsed')) {
                            return '<div>' + T.translate('dashboard.billedUsage') + ' <span style="width:12px; height:12px; background-color:#ecb67c; display:inline-block; border-radius:500px; margin-left:20px; margin-right:4px;"></span>' + T.translate('dashboard.unbilledUsage') + '</div>';
                        } else {
                            return T.translate('dashboard.energySpend');
                        }

                    }
                },
                plotOptions: {
                    column: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: (e) => {
                                    if (e.point && e.point.category) {
                                        this.toggleIntervalElectricity(e.point.category);
                                    }
                                }
                            }
                        }
                    }
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    },
                    {
                        name: T.translate('dashboard.energySpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ],
                drilldown: {
                    series: []
                }
            },
            highchartsOptionDailyWithoutEnergySpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        },
                        {
                            id: 'unbilled-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: '#ecb67c',
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: {
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)',
                    useHTML: true,
                    labelFormatter: function () {

                        if (this.name === T.translate('dashboard.kWhUsed')) {
                            return '<div>' + T.translate('dashboard.billedUsage') + ' <span style="width:12px; height:12px; background-color:#ecb67c; display:inline-block; border-radius:500px; margin-left:20px; margin-right:4px;"></span>' + T.translate('dashboard.unbilledUsage') + '</div>';
                        } else {
                            return T.translate('dashboard.energySpend');
                        }

                    }
                },
                plotOptions: {
                    column: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: (e) => {
                                    if (e.point && e.point.category) {
                                        this.toggleIntervalElectricity(e.point.category);
                                    }
                                }
                            }
                        }
                    }
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    }
                ],
                drilldown: {
                    series: []
                }
            },
            highchartsOptionIntervalWithEnergySpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.energySpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    },
                    {
                        name: T.translate('dashboard.energySpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ]
            },
            highchartsOptionIntervalWithoutEnergySpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: { // Primary yAxis
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    }
                ]
            },
            highchartsOptionMonthlyWithEnergySpend: {
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.energySpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    },
                    {
                        name: T.translate('dashboard.energySpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ]
            },
            highchartsOptionMonthlyWithoutEnergySpend: {
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: {
                    title: {
                        text: T.translate('dashboard.kWhUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.unit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    }
                },
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.kWhUsed'),
                        type: 'column',
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.unit')
                        }
                    }
                ]
            },
            highchartsOptionMonthlyWithWaterSpend: {
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.waterSpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.galUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.galUnit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.galUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.galUnit')
                        }
                    },
                    {
                        name: T.translate('dashboard.waterSpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ]
            },
            highchartsOptionDailyWithWaterSpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        },
                        {
                            id: 'unbilled-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: '#ecb67c',
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.waterSpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.galUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.galUnit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)',
                    useHTML: true,
                    labelFormatter: function () {

                        if (this.name === T.translate('dashboard.galUsed')) {
                            return '<div>' + T.translate('dashboard.billedUsage') + ' <span style="width:12px; height:12px; background-color:#ecb67c; display:inline-block; border-radius:500px; margin-left:20px; margin-right:4px;"></span>' + T.translate('dashboard.unbilledUsage') + '</div>';
                        } else {
                            return T.translate('dashboard.waterSpend');
                        }

                    }
                },
                plotOptions: {
                    column: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: (e) => {
                                    if (e.point && e.point.category) {
                                        this.toggleIntervalWater(e.point.category);
                                    }
                                }
                            }
                        }
                    }
                },
                series: [
                    {
                        name: T.translate('dashboard.galUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.galUnit')
                        }
                    },
                    {
                        name: T.translate('dashboard.waterSpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ],
                drilldown: {
                    series: []
                }
            },
            highchartsOptionIntervalWithWaterSpend: {
                defs: {
                    patterns: [
                        {
                            id: 'billed-estimated-pattern',
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                stroke: Highcharts.getOptions().colors[0],
                                strokeWidth: 3
                            }
                        }
                    ]
                },
                credits: {
                    enabled: false
                },
                title: '',
                chart: {
                    zoomType: 'xy',
                    style: {
                        fontFamily: "sans-serif;"
                    }
                },
                scrollbar: {
                    enabled: true
                },
                xAxis: [{
                    categories: [],
                    crosshair: true,
                    max: null
                }],
                yAxis: [{ // Primary yAxis
                    title: {
                        text: T.translate('dashboard.waterSpend'),
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    labels: {
                        format: '${value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: T.translate('dashboard.galUsed'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value} ' + T.translate('dashboard.galUnit'),
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                legend: {
                    layout: 'vertical',
                    align: 'left',
                    x: 70,
                    verticalAlign: 'top',
                    y: 10,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
                },
                series: [
                    {
                        name: T.translate('dashboard.galUsed'),
                        type: 'column',
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: ' ' + T.translate('dashboard.galUnit')
                        }
                    },
                    {
                        name: T.translate('dashboard.waterSpend'),
                        type: 'spline',
                        data: [],
                        tooltip: {
                            valuePrefix: '$'
                        }
                    }
                ]
            }
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('dashboard.title');

        if (this.props.account.accountNumber) {
            this.props.getAccountActivity(this.state.chartEnergyTypeID);
        }
    }

    componentWillReceiveProps(nextProps) {

        //Header Banners Logic
        let bannersHeader = [];
        let banersHeaderCounter = 0;
        if (nextProps.accountHeaderBanners && nextProps.accountHeaderBanners.length) {
            nextProps.accountHeaderBanners.map(function (banner, i) {
                if (banner.bannerTemplateDetails && banner.bannerTemplateDetails.length) {
                    banner.bannerTemplateDetails.map(function (bannerDetails, i) {
                        if (bannerDetails.languageID === nextProps.langID && banersHeaderCounter < 3) {
                            bannersHeader.push(bannerDetails);
                        }
                    });
                    banersHeaderCounter++;
                }                
            });
        }
        this.setState({ bannersHeader: bannersHeader });

        //Marketing Banners Logic
        let bannerMarketing = undefined;
        if (nextProps.accountHeaderBanners && nextProps.accountMarketingBanners.length) {
            var banner = nextProps.accountMarketingBanners[0];
            if (banner.bannerTemplateDetails && banner.bannerTemplateDetails.length) {
                banner.bannerTemplateDetails.map(function (bannerDetails, i) {
                    if (bannerDetails.languageID === nextProps.langID) {
                        bannerMarketing = bannerDetails;
                    }
                });
            }            
        }
        this.setState({ bannerMarketing: bannerMarketing });

        //when user refresh the browser - once we get the account - get data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getAccountActivity(this.state.chartEnergyTypeID);
        }

        if (this.props.accountServicePointID > 0 && this.props.accountServicePointID !== nextProps.accountServicePointID) {
            //if account service point changed then get activity for it
            this.props.getAccountActivity(this.state.chartEnergyTypeID);
        }

        if (this.state.activeTab === '0' && (nextProps.account.electricity || nextProps.account.water)) {
            if (nextProps.account.electricity) {
                this.setState({ activeTab: '1' });
            } else if (nextProps.account.water) {
                this.setState({ activeTab: '3' });
            }
        }

        if (nextProps.activityMonthlyElectricityCategories && nextProps.activityMonthlyElectricitySeries && nextProps.activityMonthlyElectricitySeries2) {

            let highchartsOptionMonthlyWithEnergySpend = { ...this.state.highchartsOptionMonthlyWithEnergySpend };

            highchartsOptionMonthlyWithEnergySpend.xAxis[0].categories = nextProps.activityMonthlyElectricityCategories;
            highchartsOptionMonthlyWithEnergySpend.series[0].data = nextProps.activityMonthlyElectricitySeries;
            highchartsOptionMonthlyWithEnergySpend.series[1].data = nextProps.activityMonthlyElectricitySeries2;
            highchartsOptionMonthlyWithEnergySpend.scrollbar = {};
            highchartsOptionMonthlyWithEnergySpend.scrollbar.enabled = nextProps.activityMonthlyElectricityCategories.length > 15;
            highchartsOptionMonthlyWithEnergySpend.xAxis[0].max = nextProps.activityMonthlyElectricityCategories.length > 15 ? 13 : null;

            this.setState({ highchartsOptionMonthlyWithEnergySpend });

            if (this.refs.highChartsMonthlyWithEnergySpend) {
                //reset extremes after updating values - then redraw chart
                this.refs.highChartsMonthlyWithEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                this.refs.highChartsMonthlyWithEnergySpend.chart.redraw();
            }

            let highchartsOptionMonthlyWithoutEnergySpend = { ...this.state.highchartsOptionMonthlyWithoutEnergySpend };

            highchartsOptionMonthlyWithoutEnergySpend.xAxis[0].categories = nextProps.activityMonthlyElectricityCategories;
            highchartsOptionMonthlyWithoutEnergySpend.series[0].data = nextProps.activityMonthlyElectricitySeries;
            highchartsOptionMonthlyWithoutEnergySpend.scrollbar = {};
            highchartsOptionMonthlyWithoutEnergySpend.scrollbar.enabled = nextProps.activityMonthlyElectricityCategories.length > 15;
            highchartsOptionMonthlyWithoutEnergySpend.xAxis[0].max = nextProps.activityMonthlyElectricityCategories.length > 15 ? 13 : null;

            this.setState({ highchartsOptionMonthlyWithoutEnergySpend });

            if (this.refs.highChartsMonthlyWithoutEnergySpend) {
                //reset extremes after updating values - then redraw chart
                this.refs.highChartsMonthlyWithoutEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                this.refs.highChartsMonthlyWithoutEnergySpend.chart.redraw();
            }

        }

        if (nextProps.activityDailyElectricityCategories && nextProps.activityDailyElectricitySeries && nextProps.activityDailyElectricitySeries2) {

            let highchartsOptionDailyWithoutEnergySpend = { ...this.state.highchartsOptionDailyWithoutEnergySpend };

            highchartsOptionDailyWithoutEnergySpend.xAxis[0].categories = nextProps.activityDailyElectricityCategories;
            highchartsOptionDailyWithoutEnergySpend.series[0].data = nextProps.activityDailyElectricitySeries;
            highchartsOptionDailyWithoutEnergySpend.scrollbar = {};
            highchartsOptionDailyWithoutEnergySpend.scrollbar.enabled = nextProps.activityDailyElectricityCategories.length > 15;
            highchartsOptionDailyWithoutEnergySpend.xAxis[0].max = nextProps.activityDailyElectricityCategories.length > 15 ? 13 : null;

            this.setState({ highchartsOptionDailyWithoutEnergySpend });

            if (this.refs.highChartsDailyWithoutEnergySpend) {
                this.refs.highChartsDailyWithoutEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                this.refs.highChartsDailyWithoutEnergySpend.chart.redraw();
            }

            let highchartsOptionDailyWithEnergySpend = { ...this.state.highchartsOptionDailyWithEnergySpend };

            highchartsOptionDailyWithEnergySpend.xAxis[0].categories = nextProps.activityDailyElectricityCategories;
            highchartsOptionDailyWithEnergySpend.series[0].data = nextProps.activityDailyElectricitySeries;
            highchartsOptionDailyWithEnergySpend.series[1].data = nextProps.activityDailyElectricitySeries2;
            highchartsOptionDailyWithEnergySpend.scrollbar = {};
            highchartsOptionDailyWithEnergySpend.scrollbar.enabled = nextProps.activityDailyElectricityCategories.length > 15;
            highchartsOptionDailyWithEnergySpend.xAxis[0].max = nextProps.activityDailyElectricityCategories.length > 15 ? 13 : null;

            this.setState({ highchartsOptionDailyWithEnergySpend });

            if (this.refs.highChartsDailyWithEnergySpend) {
                this.refs.highChartsDailyWithEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                this.refs.highChartsDailyWithEnergySpend.chart.redraw();
            }

        }

        if (nextProps.activityIntervalElectricityCategories && nextProps.activityIntervalElectricitySeries && nextProps.activityIntervalElectricitySeries2) {

            let highchartsOptionIntervalWithoutEnergySpend = { ...this.state.highchartsOptionIntervalWithoutEnergySpend };

            highchartsOptionIntervalWithoutEnergySpend.xAxis[0].categories = nextProps.activityIntervalElectricityCategories;
            highchartsOptionIntervalWithoutEnergySpend.series[0].data = nextProps.activityIntervalElectricitySeries;
            highchartsOptionIntervalWithoutEnergySpend.scrollbar = {};
            highchartsOptionIntervalWithoutEnergySpend.scrollbar.enabled = nextProps.activityIntervalElectricityCategories.length > 15;
            highchartsOptionIntervalWithoutEnergySpend.xAxis[0].max = nextProps.activityIntervalElectricityCategories.length > 15 ? 13 : null;

            this.setState({ highchartsOptionIntervalWithoutEnergySpend });

            if (this.refs.highChartsIntervalWithoutEnergySpend) {
                this.refs.highChartsIntervalWithoutEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                this.refs.highChartsIntervalWithoutEnergySpend.chart.redraw();
            }

            let highchartsOptionIntervalWithEnergySpend = { ...this.state.highchartsOptionIntervalWithEnergySpend };

            highchartsOptionIntervalWithEnergySpend.xAxis[0].categories = nextProps.activityIntervalElectricityCategories;
            highchartsOptionIntervalWithEnergySpend.series[0].data = nextProps.activityIntervalElectricitySeries;
            highchartsOptionIntervalWithEnergySpend.series[1].data = nextProps.activityIntervalElectricitySeries2;
            highchartsOptionIntervalWithEnergySpend.scrollbar = {};
            highchartsOptionIntervalWithEnergySpend.scrollbar.enabled = nextProps.activityIntervalElectricityCategories.length > 15;
            highchartsOptionIntervalWithEnergySpend.xAxis[0].max = nextProps.activityIntervalElectricityCategories.length > 15 ? 13 : null;

            this.setState({ highchartsOptionIntervalWithEnergySpend });

            if (this.refs.highChartsIntervalWithEnergySpend) {
                this.refs.highChartsIntervalWithEnergySpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                this.refs.highChartsIntervalWithEnergySpend.chart.redraw();
            }

        }

        if (nextProps.activityMonthlyWaterCategories && nextProps.activityMonthlyWaterSeries && nextProps.activityMonthlyWaterSeries2) {

            let highchartsOptionMonthlyWithWaterSpend = { ...this.state.highchartsOptionMonthlyWithWaterSpend };

            highchartsOptionMonthlyWithWaterSpend.xAxis[0].categories = nextProps.activityMonthlyWaterCategories;
            highchartsOptionMonthlyWithWaterSpend.series[0].data = nextProps.activityMonthlyWaterSeries;
            highchartsOptionMonthlyWithWaterSpend.series[1].data = nextProps.activityMonthlyWaterSeries2;
            highchartsOptionMonthlyWithWaterSpend.scrollbar = {};
            highchartsOptionMonthlyWithWaterSpend.scrollbar.enabled = nextProps.activityMonthlyWaterCategories.length > 15;
            highchartsOptionMonthlyWithWaterSpend.xAxis[0].max = nextProps.activityMonthlyWaterCategories.length > 15 ? 13 : null;

            this.setState({ highchartsOptionMonthlyWithWaterSpend });

            if (this.refs.highChartsMonthlyWithWaterSpend) {
                //reset extremes after updating values - then redraw chart
                this.refs.highChartsMonthlyWithWaterSpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                this.refs.highChartsMonthlyWithWaterSpend.chart.redraw();
            }

        }

        if (nextProps.activityDailyWaterCategories && nextProps.activityDailyWaterSeries && nextProps.activityDailyWaterSeries2) {

            let highchartsOptionDailyWithWaterSpend = { ...this.state.highchartsOptionDailyWithWaterSpend };

            highchartsOptionDailyWithWaterSpend.xAxis[0].categories = nextProps.activityDailyWaterCategories;
            highchartsOptionDailyWithWaterSpend.series[0].data = nextProps.activityDailyWaterSeries;
            highchartsOptionDailyWithWaterSpend.series[1].data = nextProps.activityDailyWaterSeries2;
            highchartsOptionDailyWithWaterSpend.scrollbar = {};
            highchartsOptionDailyWithWaterSpend.scrollbar.enabled = nextProps.activityDailyWaterCategories.length > 15;
            highchartsOptionDailyWithWaterSpend.xAxis[0].max = nextProps.activityDailyWaterCategories.length > 15 ? 13 : null;

            this.setState({ highchartsOptionDailyWithWaterSpend });

            if (this.refs.highChartsDailyWithWaterSpend) {
                this.refs.highChartsDailyWithWaterSpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                this.refs.highChartsDailyWithWaterSpend.chart.redraw();
            }

        }

        if (nextProps.activityIntervalWaterCategories && nextProps.activityIntervalWaterSeries && nextProps.activityIntervalWaterSeries2) {

            let highchartsOptionIntervalWithWaterSpend = { ...this.state.highchartsOptionIntervalWithWaterSpend };

            highchartsOptionIntervalWithWaterSpend.xAxis[0].categories = nextProps.activityIntervalWaterCategories;
            highchartsOptionIntervalWithWaterSpend.series[0].data = nextProps.activityIntervalWaterSeries;
            highchartsOptionIntervalWithWaterSpend.series[1].data = nextProps.activityIntervalWaterSeries2;
            highchartsOptionIntervalWithWaterSpend.scrollbar = {};
            highchartsOptionIntervalWithWaterSpend.scrollbar.enabled = nextProps.activityIntervalWaterCategories.length > 15;
            highchartsOptionIntervalWithWaterSpend.xAxis[0].max = nextProps.activityIntervalWaterCategories.length > 15 ? 13 : null;

            this.setState({ highchartsOptionIntervalWithWaterSpend });

            if (this.refs.highChartsIntervalWithWaterSpend) {
                this.refs.highChartsIntervalWithWaterSpend.chart.xAxis[0].setExtremes(undefined, undefined, true, true);
                this.refs.highChartsIntervalWithWaterSpend.chart.redraw();
            }

        }

        this.getCalculationLegend();
    }

    toggle = (e) => {
        var tab = e.target.attributes['data-tab'].value;
        this.setState({ activeTab: tab });

        this.getCalculationLegend(tab);
    }

    toggleIntervalElectricity = (category) => {
        this.setState({ intervalElectricity: true, intervalDateElectricity: category });
        this.props.getAccountActivity('3', category);
    }

    toggleIntervalWater = (category) => {
        this.setState({ intervalWater: true, intervalDateWater: category });
        this.props.getAccountActivity('3', category);
    }

    backToDailyElectricity = (e) => {
        this.setState({ intervalElectricity: false, intervalDateElectricity: '' });
    }

    backToDailyWater = (e) => {
        this.setState({ intervalWater: false, intervalDateWater: '' });
    }

    hrefValue = () => {
        var token = this.props.getPrivateToken();
        var billID = this.props.account.lastBillID;
        var accountID = this.props.account.accountID;
        var url = 'api/data/Bill?accountID=' + accountID + '&billID=' + billID + '&t=' + encodeURIComponent(token.replace('bearer ', ''));
        return url;
    }

    getCalculationLegend = (activeTab) => {

        let calculationLegendText = '';
        let periodID = 0;
        let resourceKey = '';
        let activityStatistics = {};

        if (!activeTab) {
            activeTab = this.state.activeTab;
        }

        if (activeTab === '1') {

            periodID = this.state.chartEnergyTypeID;

            if (periodID === '1') {
                activityStatistics = this.props.activityElectricityMonthlyStatistics;
            } else if (periodID === '2') {
                activityStatistics = this.props.activityElectricityDailyStatistics;
            }

        } else if (activeTab === '3') {

            periodID = this.state.chartWaterTypeID;

            if (periodID === '1') {
                activityStatistics = this.props.activityWaterMonthlyStatistics;
            } else if (periodID === '2') {
                activityStatistics = this.props.activityWaterDailyStatistics;
            }
        }

        if (activityStatistics) {

            if (activeTab === '1') {
                activityStatistics.unit = T.translate('dashboard.unit');
            } else if (activeTab === '3') {
                activityStatistics.unit = T.translate('dashboard.galUnit');
            }

            if (activityStatistics.isUsagePresent) {

                if (periodID === '1') {

                    activityStatistics.perMonth.formatedDate = CommonStore.formatDate(activityStatistics.perMonth.date, 'MMMM YYYY');
                    resourceKey = '';

                    if (activityStatistics.perMonth.isPreviousMonthPesent || activityStatistics.perMonth.isPreviousYearMonthPesent) {

                        activityStatistics.perMonth.formatedPreviousMonthDate = CommonStore.formatDate(activityStatistics.perMonth.previousMonthDate, 'MMMM YYYY');

                        var resourceKeyArr = [];

                        if (activityStatistics.perMonth.previousMonthAmountDiff > 0) {
                            resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV2MoreThan');
                        } else if (activityStatistics.perMonth.previousMonthAmountDiff < 0) {
                            resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV2LessThan');
                        } else {
                            resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV2Equal');
                        }

                        if (activityStatistics.perMonth.isPreviousYearMonthPesent) {
                            if (activityStatistics.perMonth.previousYearMonthAmountDiff > 0) {
                                resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV3MoreThan');
                            } else if (activityStatistics.perMonth.previousYearMonthAmountDiff < 0) {
                                resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV3LessThan');
                            } else {
                                resourceKeyArr.push('dashboard.yourAvgDailyCostMonthV3Equal');
                            }
                        }

                        let translations = resourceKeyArr.map(x => T.translate(x, activityStatistics));

                        calculationLegendText = translations.join(' ');

                    } else {
                        resourceKey = 'dashboard.yourAvgDailyCostMonthV1';
                        calculationLegendText = T.translate(resourceKey, activityStatistics);
                    }
                } else if (periodID === '2') {

                    activityStatistics.perDay.formatedFromDate = CommonStore.formatDate(activityStatistics.perDay.startDate, 'MM/DD/YYYY');
                    activityStatistics.perDay.formatedToDate = CommonStore.formatDate(activityStatistics.perDay.endDate, 'MM/DD/YYYY');

                    if (activityStatistics.perDay.isPreviousWeekPesent) {

                        if (activityStatistics.perDay.previousWeekAmountDiff > 0) {
                            resourceKey = 'dashboard.yourAvgDailyCostWeekV2MoreThan';
                        } else if (activityStatistics.perDay.previousWeekAmountDiff < 0) {
                            resourceKey = 'dashboard.yourAvgDailyCostWeekV2LessThan';
                        } else {
                            resourceKey = 'dashboard.yourAvgDailyCostWeekV2Equal';
                        }

                    } else {
                        resourceKey = 'dashboard.yourAvgDailyCostWeekV1';
                    }

                    calculationLegendText = T.translate(resourceKey, activityStatistics);
                }
            }

        }

        this.setState({ calculationLegendText });
    }

    onChartEnergyChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);

        this.props.getAccountActivity(this.state.chartEnergyTypeID);

        if (this.state.chartEnergyTypeID === '1') {
            this.backToDailyElectricity();
        }

        this.getCalculationLegend();
    }

    onChartWaterChange = (e) => {
        const state = this.state;
        state[e.target.name] = e.target.value;
        this.setState(state);

        this.props.getAccountActivity(this.state.chartWaterTypeID);

        if (this.state.chartWaterTypeID === '1') {
            this.backToDailyElectricity();
        }

        this.getCalculationLegend();
    }

    getExternalRenewalUrl = () => {
        var renewalUrl = this.props.systemProperties.myAcctRenewalUrlRedirection.replace("{accountNumber}", this.props.account.accountNumber);
        return renewalUrl;
    }

    hasExternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection !== '') {
            return true;
        }
        return false;
    }

    hasInternalRenewalContent = (hasRenewalOffers) => {
        if (hasRenewalOffers && this.props.systemProperties.myAcctRenewalUrlRedirection === '') {
            return true;
        }
        return false;
    }

    render() {

        let accountBalanceValue = CommonStore.formatDecimal(this.props.invertBalance ? Math.abs(this.props.account.accountBalance) : this.props.account.accountBalance);
        // Available balance is green the label should be green (credit), if value is red then label should be red (debit), when zero balance it should black
        let availableBalanceTextColor = (this.props.account.availableBalance === 0) || (this.props.account.availableBalance === '0') ? "font-weight-bold" :
                                        (this.props.account.availableBalance > 0) ? "text-success font-weight-bold" : "text-danger font-weight-bold"
        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('dashboard.pageTitle')}</div>
                    <p className="lead text-dark font-weight-normal kanit">{T.translate('dashboard.accountNumber')}: {this.props.account.accountNumber}</p>

                    {this.props.account.hasRenewalOffers && <div className="alert bg-success text-white mb-4">
                        <div className="row d-flex align-self-center banner">
                            <div className="col-lg-7 d-flex align-self-center">
                                <FontAwesomeIcon size="2x" className="mr-3" icon={faCheckCircle} />
                                <div>
                                    {T.translate('dashboard.renewalSuggestion', this.props.systemProperties)}
                                </div>
                            </div>
                            {this.hasInternalRenewalContent(this.props.account.hasRenewalOffers) && <div className="col-lg-5">
                                <NavLink to="/myaccount/renewal" className="btn btn-primary">{T.translate('dashboard.renewService')}</NavLink>
                            </div>}
                            {this.hasExternalRenewalContent(this.props.account.hasRenewalOffers) && <div className="col-lg-5">
                                {<a href={this.getExternalRenewalUrl()} target="_blank" className="btn btn-primary">{T.translate('dashboard.renewService')}</a>}
                            </div>}
                        </div>
                    </div>}

                    

                    {this.state.bannersHeader.map(banner => (
                        <div key={banner.bannerTemplateDetailID} className="alert bg-success text-white mb-4">
                            <div className="row d-flex align-self-center banner">
                                <div className="col-lg-7 d-flex align-self-center">
                                    <FontAwesomeIcon size="2x" className="mr-3" icon={faCheckCircle} />
                                    <div>
                                        {banner.bannerBody}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {this.props.account.isCashOnly && <div className="alert bg-info text-white mb-4">
                        <div className="d-flex align-self-center">
                            <FontAwesomeIcon size="2x" className="mr-3" icon={faExclamationCircle} />
                            <div className="d-flex align-self-center">{T.translate('dashboard.isCashOnlyAccountMessage', this.props.systemProperties)}</div>
                        </div>
                    </div>}

                    {this.props.showAccountWarning &&
                        <div className="alert alert-danger alert-primary" role="alert">
                            <p className="mg-bm-5">{T.translate('dashboard.accountWarning1')}</p>
                            <p className="mg-bm-5">{T.translate('dashboard.accountWarning2')}</p>
                            <p className="mg-bm-5">{T.translate('dashboard.accountWarning3')}</p>
                            <p className="mg-bm-5">{T.translate('dashboard.accountWarning4')}</p>
                        </div>
                    }

                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="card mb-4" id="divAccountDashboard" style={{ height: '98%' }}>

                            {this.props.account.accountBillingTypeID === 1 && <div className="card-body">
                                <h6 className="card-title">{T.translate('dashboard.prepaySummary')}</h6>
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="row">
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.availableBalance')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <strong className={availableBalanceTextColor}>${CommonStore.formatDecimal(this.props.account.availableBalance)}</strong> {this.props.account.availableBalance > 0 ? <span className={availableBalanceTextColor}>{T.translate('dashboard.credit')}</span> : <span className={availableBalanceTextColor}>{T.translate('dashboard.debit')}</span>}
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.disconnectThreshold')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="mb-0">${CommonStore.formatDecimal(this.props.account.disconnectThreshold)}</span>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.accountBalance')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="mb-0"> ${accountBalanceValue}</span> {this.props.account.accountBalance > 0 && <span className="mb-0">{T.translate('dashboard.credit')}</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 text-center align-self-center">
                                        <div className="progress">
                                            <div className="baroverflow">
                                                <Animate easing="ease" from={{ y: 0 }} to={{ y: this.props.account.daysRemainingCount }}>
                                                    {({ y }) => <div className="bar" style={{ transform: `rotate(${45 + y * 6}deg)` }} />}
                                                </Animate>
                                            </div>
                                            <div className="progress-count">
                                                <Animate from={{ y: 0 }} to={{ y: this.props.account.daysRemaining }}>
                                                    {({ y }) => <span>{parseInt(y)}</span>}
                                                </Animate>
                                            </div>
                                        </div>
                                        <h5 className="est-remaning">{T.translate('dashboard.estDaysRemaining')}</h5>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="row">
                                            {this.props.showEnergySpend && <React.Fragment>
                                                <div className="col-md-6 pt-2 pb-2">
                                                    <p className="mb-0">{T.translate('dashboard.avgDailyUsage')}:</p>
                                                </div>
                                                <div className="col-md-6 pt-2 pb-2">
                                                    <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.averageDailyUsage)}</span>
                                                </div>
                                            </React.Fragment>}
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.avgDailyKwhUsage')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">{CommonStore.formatDecimal(this.props.account.averageDailyKwh)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 text-left text-md-center align-self-center">
                                        {T.translate('dashboard.meterStatus')}<span className={(this.props.account.meterStatusID === 2 ? "bg-success" : "bg-danger") + " ml-3 text-white rounded font-weight-normal py-1 px-3 sp-tag"}>{this.props.account.meterStatus}</span>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="row">
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.paymentPlanBalance')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.debtBalance)}</span>
                                            </div>
                                            {this.props.account.lastPaymentAmount > 0 && <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.lastPayment')}:</p>
                                            </div>}
                                            {this.props.account.lastPaymentAmount > 0 && <div className="col-md-6 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.lastPaymentAmount)} on {CommonStore.formatDate(this.props.account.lastPaymentDate)}</span>
                                            </div>}
                                        </div>
                                    </div>
                                    {!this.props.account.isCashOnly && <div className="col-md-5 text-left text-md-center align-self-center">
                                        <NavLink to="/myaccount/paycreditcard" className="btn btn-pay">{T.translate('dashboard.payNow')}</NavLink>
                                    </div>}
                                </div>
                            </div>}

                            {this.props.account.accountBillingTypeID !== 1 && <div className="card-body">
                                <h6 className="card-title">{T.translate('dashboard.billingSummary')}</h6>
                                <div className="row">
                                    <div className="col-md-6 align-self-center">
                                        <div className="row">
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.accountBalance')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <strong className={this.props.account.accountBalance < 0 ? "text-danger font-weight-bold" : "text-success font-weight-bold"}>${CommonStore.formatDecimal(this.props.invertBalance ? Math.abs(this.props.account.accountBalance) : this.props.account.accountBalance)}</strong> {this.props.account.accountBalance > 0 && <span className="text-success font-weight-bold">{T.translate('dashboard.credit')}</span>}
                                            </div>
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.nextDueDate')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">{CommonStore.formatDate(this.props.account.paymentDueDate)}</span>
                                            </div>
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.totalAmountDue')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.totalAmountDue)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {!this.props.account.isCashOnly && <div className="col-md-6 text-left text-md-center align-self-center">
                                        <NavLink to="/myaccount/paycreditcard" className="btn btn-primary">{T.translate('dashboard.payMyBill')}</NavLink>
                                    </div>}
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-md-6 align-self-center">
                                        <div className="row">
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.currentAmountDue')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.currentAmountDue)}</span>
                                            </div>
                                            <div className="col-md-7 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.pastDue')}:</p>
                                            </div>
                                            <div className="col-md-5 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.pastDueAmount)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-left text-md-center align-self-center">

                                        {this.props.account.lastBillID > 0 && <ExternalLink href={this.hrefValue()} className="btn btn-outline-dark m-2">{T.translate('dashboard.viewMyBill')}</ExternalLink>}

                                        {false && <NavLink to="/myaccount/monthlystatements" className="btn btn-outline-dark m-2">{T.translate('dashboard.viewMyBill')}</NavLink>}
                                        <NavLink to="/myaccount/monthlystatements" className="btn btn-outline-dark m-2">{T.translate('dashboard.viewPastStatement')}</NavLink>
                                    </div>
                                </div>
                                <hr />
                                {this.props.account.lastPaymentAmount > 0 && <div className="row">
                                    <div className="col-md-7 align-self-center">
                                        <div className="row">
                                            <div className="col-md-6 pt-2 pb-2">
                                                <p className="mb-0">{T.translate('dashboard.lastPayment')}:</p>
                                            </div>
                                            <div className="col-md-6 pt-2 pb-2">
                                                <span className="text-dark font-weight-normal">${CommonStore.formatDecimal(this.props.account.lastPaymentAmount)} on {CommonStore.formatDate(this.props.account.lastPaymentDate)}</span>
                                                {false && <p className="text-success mb-0 kanit">{T.translate('dashboard.autoPayScheduledFor')}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>}

                        </div>
                    </div>
                    {this.state.bannerMarketing && <div className="col-lg-4">
                        <div className="card mb-4" style={{ height: '98%' }}>
                            <div className="card-body img-fluid-container">
                                <div dangerouslySetInnerHTML={{ __html: this.state.bannerMarketing.bannerBodyHtml }} ></div>
                            </div>
                        </div>
                    </div>}

                    {!this.state.bannerMarketing && <div className="col-lg-4">
                        <div className="card mb-4" style={{ height: '98%' }}>
                            <div className="card-body">
                                <img src={refer} className="img-fluid" alt={T.translate('dashboard.tmpDinamicBannerTitle')} />
                                <h6 className="card-title mt-3">{T.translate('dashboard.tmpDinamicBannerTitle')}</h6>
                                <span>{T.translate('dashboard.tmpDinamicBannerParagraph')}</span>
                                {false && <React.Fragment><br />
                                    <p className="mb-4">Use Code <strong>XYRAZ</strong> when referring.</p>
                                </React.Fragment>}
                            </div>
                        </div>
                        {false && <div className="card">
                            <div className="card-body">
                                <img src={autopay} className="img-fluid" alt="autopay" />
                                <h6 className="card-title mt-3">You're not enrolled in Auto Pay</h6>
                                <p>
                                    Save a boat load of time plus 20% off our standard
                                    rates by enrolling in Auto Pay today!
                                </p>
                                <NavLink to="/myaccount/autopay" className="btn btn-primary">Set up auto pay</NavLink>
                            </div>
                        </div>}
                    </div>
                    }
                </div>  


                <div className="row">
                    <div className="col-lg-12">
                        <ul className="nav nav-tabs">
                            {this.props.account.electricity && <li className="nav-item"><a href="javascript:;" data-tab="1" className={"nav-link " + (this.state.activeTab === '1' ? "active" : "")} onClick={this.toggle}>{T.translate('dashboard.power')}</a></li>}
                            {this.props.account.water && <li className="nav-item"><a href="javascript:;" data-tab="3" className={"nav-link " + (this.state.activeTab === '3' ? "active" : "")} onClick={this.toggle}>{T.translate('dashboard.water')}</a></li>}
                        </ul>
                        <div className="tab-content mb-4">

                            {this.state.activeTab === '1' && <div className={"tab-pane " + (this.state.activeTab === '1' ? "active" : "")}>
                                <div className="row">
                                    <div className="col-md-9">

                                        {this.state.chartEnergyTypeID === '1' && this.props.showEnergySpend &&
                                            <HighchartsReact
                                                ref="highChartsMonthlyWithEnergySpend"
                                                highcharts={Highcharts}
                                                options={this.state.highchartsOptionMonthlyWithEnergySpend}
                                            />
                                        }

                                        {this.state.chartEnergyTypeID === '1' && !this.props.showEnergySpend &&
                                            <HighchartsReact
                                                ref="highChartsMonthlyWithoutEnergySpend"
                                                highcharts={Highcharts}
                                                options={this.state.highchartsOptionMonthlyWithoutEnergySpend}
                                            />
                                        }

                                        {this.state.chartEnergyTypeID === '2' && !this.state.intervalElectricity && this.props.showEnergySpend &&
                                            <HighchartsReact
                                                ref="highChartsDailyWithEnergySpend"
                                                highcharts={Highcharts}
                                                options={this.state.highchartsOptionDailyWithEnergySpend}
                                            />
                                        }

                                        {this.state.chartEnergyTypeID === '2' && !this.state.intervalElectricity && !this.props.showEnergySpend &&
                                            <HighchartsReact
                                                ref="highChartsDailyWithoutEnergySpend"
                                                highcharts={Highcharts}
                                                options={this.state.highchartsOptionDailyWithoutEnergySpend}
                                            />
                                        }

                                        {this.state.chartEnergyTypeID === '2' && this.state.intervalElectricity && (this.props.account.accountBillingTypeID === 2 || !this.props.showEnergySpend) &&
                                            <HighchartsReact
                                                ref="highChartsIntervalWithoutEnergySpend"
                                                highcharts={Highcharts}
                                                options={this.state.highchartsOptionIntervalWithoutEnergySpend}
                                            />
                                        }

                                        {this.state.chartEnergyTypeID === '2' && this.state.intervalElectricity && this.props.account.accountBillingTypeID === 1 && this.props.showEnergySpend &&
                                            <HighchartsReact
                                                ref="highChartsIntervalWithEnergySpend"
                                                highcharts={Highcharts}
                                                options={this.state.highchartsOptionIntervalWithEnergySpend}
                                            />
                                        }

                                    </div>
                                    <div className="col-md-3 align-self-center">
                                        <img src={plug} alt="plug icon" />
                                        <h6 className="mt-3 mb-3 card-title">{T.translate('dashboard.myUsageInsights')}</h6>

                                        {!this.state.intervalElectricity &&
                                            <p className="mb-4">{this.state.calculationLegendText}</p>
                                        }

                                        {this.state.intervalElectricity &&
                                            <div>
                                                <p className="mb-4">{T.translate('dashboard.showingIntervalConsumptionFor')} {this.state.intervalDateElectricity}</p>
                                                <p className="mb-4"><button className="btn btn-primary" type="button" onClick={this.backToDailyElectricity}>{T.translate('dashboard.backToDailyView')}</button></p>
                                            </div>
                                        }

                                        {!this.state.intervalElectricity && <select className="form-control" id="chartEnergyTypeID" name="chartEnergyTypeID" value={this.state.chartEnergyTypeID} onChange={this.onChartEnergyChange}>
                                            <option value="1">{T.translate('dashboard.monthly')}</option>
                                            <option value="2">{T.translate('dashboard.daily')}</option>
                                        </select>}
                                    </div>
                                </div>
                                <div className="text-center">{T.translate('dashboard.readsReceivedWithEstimatedStatus')}</div>
                            </div>}

                            {this.state.activeTab === '3' && <div className={"tab-pane " + (this.state.activeTab === '3' ? "active" : "")}>
                                <div className="row">
                                    <div className="col-md-9">

                                        {this.state.chartWaterTypeID === '1' &&
                                            <HighchartsReact
                                                ref="highChartsMonthlyWithWaterSpend"
                                                highcharts={Highcharts}
                                                options={this.state.highchartsOptionMonthlyWithWaterSpend}
                                            />
                                        }

                                        {this.state.chartWaterTypeID === '2' && !this.state.intervalWater &&
                                            <HighchartsReact
                                                ref="highChartsDailyWithWaterSpend"
                                                highcharts={Highcharts}
                                                options={this.state.highchartsOptionDailyWithWaterSpend}
                                            />
                                        }

                                        {this.state.chartWaterTypeID === '2' && this.state.intervalWater &&
                                            <HighchartsReact
                                                ref="highChartsIntervalWithWaterSpend"
                                                highcharts={Highcharts}
                                                options={this.state.highchartsOptionIntervalWithWaterSpend}
                                            />
                                        }

                                    </div>
                                    <div className="col-md-3 align-self-center">
                                        <img src={plugWater} alt="plug icon" />
                                        <h6 className="mt-3 mb-3 card-title">{T.translate('dashboard.myUsageInsights')}</h6>

                                        {!this.state.intervalWater &&
                                            <p className="mb-4">{this.state.calculationLegendText}</p>
                                        }

                                        {this.state.intervalWater &&
                                            <div>
                                                <p className="mb-4">{T.translate('dashboard.showingIntervalConsumptionFor')} {this.state.intervalDateWater}</p>
                                                <p className="mb-4"><button className="btn btn-primary" type="button" onClick={this.backToDailyWater}>{T.translate('dashboard.backToDailyView')}</button></p>
                                            </div>
                                        }

                                        {!this.state.intervalWater && <select className="form-control" id="chartWaterTypeID" name="chartWaterTypeID" value={this.state.chartWaterTypeID} onChange={this.onChartWaterChange}>
                                            <option value="1">{T.translate('dashboard.monthly')}</option>
                                            <option value="2">{T.translate('dashboard.daily')}</option>
                                        </select>}
                                    </div>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.common }),
    dispatch => bindActionCreators({ ...AccountStore.actionCreators, ...CommonStore.actionCreators }, dispatch)
)(Dashboard);
