import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as UserStore from '../../store/User';
import * as CommonStore from '../../store/Common';
import * as AccountStore from '../../store/Account';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';
import DataGrid from '../shared/DataGrid';
import T from 'i18n-react';

const Scroll = require('react-scroll');
const download = require('../../images/download.png');

class MonthlyStatements extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('monthlyStatement.title');

        if (this.props.account.accountNumber) {
            this.props.getStatements();
        }
    }

    componentWillReceiveProps(nextProps) {
        //when user refresh the browser - once we get the account - get screen data
        if ((nextProps.account.accountNumber && !this.props.account.accountNumber) || nextProps.account.accountNumber !== this.props.account.accountNumber) {
            this.props.getStatements();
        }
    }

    hrefValue = (row) => {
        var token = this.props.getPrivateToken();
        var url = 'api/data/Bill?accountID=' + this.props.accountID + '&billID=' + row.billID + '&isAttachment=true&t=' + encodeURIComponent(token.replace('bearer ', ''));
        return url;
    }

    render() {
        return (
            <div className="p-main">

                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    <div className="page-title">{T.translate('monthlyStatement.pageTitle')}</div>
                </div>
                <div className="diverter" />

                <MessageError>{this.props.accountErrorMessage}</MessageError>

                {this.props.accountStatements.length === 0 ? <p>{T.translate('common.noRecordsToDisplay')}</p> : <p><strong>{T.translate('monthlyStatement.displayingLastYears')}</strong></p>}

                {this.props.years.map(year =>
                    <div key={year}>
                        <h6 className="card-title">{year}</h6>
                        <DataGrid usePaging={false} dataKey="billID" hrefValue={this.hrefValue}>{{
                            rows: this.props.accountStatements.filter((row) => row.year === year),
                            columns: [
                                { columnId: 'billDate', displayText: T.translate('monthlyStatement.colBillDate'), dataType: 'date', format: 'MM/dd/yyyy', bold: true, italic: true },
                                { columnId: 'totalkWh', displayText: T.translate('monthlyStatement.colUsage'), dataType: 'int' },
                                { columnId: 'energyCharges', displayText: T.translate('monthlyStatement.colConsumptionCharges'), dataType: 'currency' },
                                { columnId: 'otherCharges', displayText: T.translate('monthlyStatement.colOtherCharges'), dataType: 'currency' },
                                { columnId: 'newCharges', displayText: T.translate('monthlyStatement.colTotalCharges'), dataType: 'currency' },
                                { columnId: 'billID', displayText: T.translate('monthlyStatement.colDownload'), dataType: 'action', image: download }
                            ]
                        }}</DataGrid>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(MonthlyStatements);