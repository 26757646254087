import T from 'i18n-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as AccountStore from '../../store/Account';
import * as CommonStore from '../../store/Common';
import * as UserStore from '../../store/User';
import BasicModal from '../shared/BasicModal';
import LoadingButton from '../shared/LoadingButton';
import LoadingOverlay from '../shared/LoadingOverlay';
import MessageError from '../shared/MessageError';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const Scroll = require('react-scroll');
const eligible = require(`../../images/eligible.png`);


class RenewService extends Component {
    constructor(props) {
        super(props);

        this.state = {
            renewalStep: 1,
            product: undefined,
            preSelectedProduct: { kwhAvgPrice: 0.0, productDisplay: '', docTypes: [] },
            acceptTOS: false,
            displayDetailsModal: false,
            selectedAspID: 0
        };
    }

    componentWillMount() {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });
        document.title = T.translate('common.client') + ' | ' + T.translate('renewService.title');

        if (this.props.account && this.props.account.servicePoints) {
            this.setDefaultLocation();
        }

        if (this.props.accountServicePointID > 0) {
            this.props.getRenewalOfferActions();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.account && this.props.account.servicePoints) {
            this.setDefaultLocation();
        }

        if (this.props.accountServicePointID > 0 && this.props.accountServicePointID !== nextProps.accountServicePointID) {
            //if account service point changed then get activity for it
            this.props.getRenewalOfferActions();
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.serviceRenewed && this.props.serviceRenewed) {
            //force account updating if service was renewed
            this.props.setAccount();
        }
    }

    setDefaultLocation() {
        var locationsListCtrl = document.getElementById("accountServicePointID");
        if (this.state.selectedAspID === 0) {
            var aspList = this.props.account.servicePoints.filter(x => x.hasRenewalOffers);

            if (aspList.length > 0) {
                var aspID = aspList[0].accountServicePointID;
                var aspIdStr = aspID.toString();

                if (locationsListCtrl.value !== aspIdStr) {
                    locationsListCtrl.value = aspIdStr;

                    this.props.setAccountServicePoint(aspID);
                    this.props.setAccount();
                }

                if (this.state.selectedAspID !== aspID) {
                    this.setState({ selectedAspID: aspID });
                }
            }
        }

        if (locationsListCtrl && locationsListCtrl.value !== this.props.accountServicePointID.toString()) {
            locationsListCtrl.value = this.props.accountServicePointID.toString();
        }
    }

    displayPlanDetails = (e) => {
        var link = e.target.closest('.plan-details-btn');
        var planDetails = link.nextElementSibling;
        var divList = link.getElementsByTagName("DIV");
        var divAngleDown = divList[0];
        var divAngleUp = divList[1];

        let display = window.getComputedStyle(planDetails).display;


        if (display === 'none') {
            display = 'block';
            divAngleDown.style.display = 'none';
            divAngleUp.style.display = '';
        } else {
            display = 'none';
            divAngleUp.style.display = 'none';
            divAngleDown.style.display = '';
        }

        planDetails.style.display = display;
    }

    showPlanDetailsModal = (e) => {
        var btnDetails = e.target;
        var productID = parseInt(btnDetails.dataset.productId);

        var preSelectedProduct = this.props.renewalOffer.products.find(p => p.productID === productID);
        this.setState({ displayDetailsModal: true, preSelectedProduct });
    }

    hidePlanDetailsModal = (e) => {
        this.setState({ displayDetailsModal: false, preSelectedProduct: { productDisplay: '', kwhAvgPrice: 0.0, docTypes: [] } });
    }

    getCents = (dollarValue) => {
        var centsValue = dollarValue * 100;
        return centsValue.toFixed(2);
    }

    getKwhAvgValue = (prod) => {
        if (prod.productDisplay.kwhAvgPriceTypeID) {
            if (prod.productDisplay.kwhAvgPriceTypeID === 1) {
                return prod.kwh500Price.toFixed(2);
            } else if (prod.productDisplay.kwhAvgPriceTypeID === 2) {
                return prod.kwh1000Price.toFixed(2);
            } if (prod.productDisplay.kwhAvgPriceTypeID === 3) {
                return prod.kwh2000Price.toFixed(2);
            }
        }
        return 0.00;
    }

    searchOffer = (e) => {
        var txtOfferCode = document.getElementById("offerCode");

        this.props.getRenewalOfferProduct(txtOfferCode.value);
    }

    nextRenewalStep = (e) => {
        Scroll.animateScroll.scrollToTop({ smooth: true, duration: 500 });

        var product = this.state.product;

        if (!product) {
            var btnRenewal = e.target;
            var productId = parseInt(btnRenewal.dataset.productId);
            product = this.props.renewalOffer.products.find(x => x.productID === productId);
        }

        var nextStep = this.state.renewalStep + 1;
        this.setState({ renewalStep: nextStep, product: product });
    }

    prevRenewalStep = (e) => {
        var nextStep = this.state.renewalStep - 1;
        this.setState({ renewalStep: nextStep, product: undefined });
    }

    renewService = (e) => {
        this.props.renewService(this.state.product);
    }

    getServiceAddress = () => {
        if (this.props.renewalOffer.accountServicePoint && this.props.renewalOffer.accountServicePoint.servicePoint) {
            var serviceAddress = this.props.renewalOffer.accountServicePoint.servicePoint.fullAddress;

            if (serviceAddress.includes(',')) {
                var arrAddressSection = serviceAddress.split(',');

                if (arrAddressSection.length === 2) {
                    return <React.Fragment>{arrAddressSection[0]}, <br /> {arrAddressSection[1]}</React.Fragment>;
                }
            }
            return <React.Fragment>{serviceAddress}</React.Fragment>;
        }

        return <React.Fragment />;
    }

    isValidDocumentTdu = (prodDoc) => {
        var sp = this.props.account.servicePoints.find(x => x.accountServicePointID === this.props.accountServicePointID);
        if (sp && prodDoc.distributionServiceProviderID !== 99) {
            return prodDoc.distributionServiceProviderID === sp.distributionServiceProviderID;
        }

        return true;
    }

    onChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ acceptTOS: e.target.checked });
        }
    }

    onChangeAccountServicePoint = (e) => {
        //Updating value on dropdown list located on LayoutInternal
        var locationsListCtrl = e.target.ownerDocument.getElementById("accountServicePointID");
        locationsListCtrl.value = e.target.value;

        var event = new CustomEvent("locationChanged", {
            target: e.target
        });

        locationsListCtrl.dispatchEvent(event);

        var aspID = parseInt(e.target.value);


        this.props.setAccountServicePoint(aspID);
        this.props.setAccount();

        this.setState({ selectedAspID: aspID });
    }

    getOfferServicePoints = () => {
        var aspList = [];

        if (this.props.account && this.props.account.servicePoints) {
            aspList = this.props.account.servicePoints.filter(x => x.hasRenewalOffers);
        }

        return aspList;
    }

    getObjectToRender = (value) => {
        return { __html: value };
    }

    render() {
        return (
            <div className="p-main">
                <LoadingOverlay loading={this.props.accountLoading} />

                <div className="page-header">
                    {(this.state.renewalStep === 1 || this.state.renewalStep === 3) && <div className="page-title">{T.translate('renewService.pageTitle')}</div>}
                    {this.state.renewalStep === 2 && <div className="page-title">{T.translate('renewService.pageTitle')}</div>}
                </div>

                <MessageError>{this.props.accountErrorMessage}</MessageError>

                {<React.Fragment>
                    {!this.props.serviceRenewed && (this.state.renewalStep === 1 || this.state.renewalStep === 2) && <React.Fragment>
                        <div className="diverter" />


                        {this.state.renewalStep === 1 &&
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-md-12">

                                        <h3 className="mb-4">{T.translate('renewService.selectAPlanForYou')}</h3>
                                        <div className="form">
                                            <label className="mr-5">{T.translate('renewService.chooseALocation')}</label>
                                            {this.getOfferServicePoints().map((sp, i) =>
                                                <div key={sp.accountServicePointID} className="custom-control custom-radio mt-3 mb-3">
                                                    <input type="radio" className="custom-control-input" id={"amountOption-" + sp.accountServicePointID} value={sp.accountServicePointID} name="accountServicePointID" checked={this.props.accountServicePointID === sp.accountServicePointID} onChange={this.onChangeAccountServicePoint} />
                                                    <label className="custom-control-label" htmlFor={"amountOption-" + sp.accountServicePointID}>{sp.fullAddress}</label>
                                                </div>
                                            )}
                                        </div>

                                        <div className="form-inline">

                                            <label className="mr-5">{T.translate('renewService.enterOfferCode')}</label>

                                            <input className="form-control mr-5" type="text" id="offerCode" name="offerCode" placeholder={T.translate('renewService.offerCode')} />

                                            <button className="btn btn-primary mr-5" onClick={this.searchOffer}>{T.translate('renewService.searchOfferCode')}</button>


                                        </div>

                                        {this.props.productOfferSearchStatus === this.props.constants.searchStatus.NOT_FOUND && <React.Fragment><p /> <p>{T.translate('renewService.invalidOfferCode')}</p></React.Fragment>}
                                        {this.props.renewalOffer && this.props.renewalOffer.products && this.props.renewalOffer.products.length === 0 && <p>{T.translate('common.noRecordsToDisplay')}</p>}

                                        <div className="row text-center">
                                            {this.props.accountServicePointID === this.state.selectedAspID && this.props.renewalOffer.products.map((prod, i) => (
                                                <div className="columns" key={prod.productID}>
                                                    <div className="price">
                                                        <div className="header">{prod.productDisplayName}</div>
                                                        {prod.isFeatured && <div className="featured-product-box-plan">
                                                            <div className="featured-title">{T.translate('renewService.featuredFlag')}</div>
                                                        </div>}
                                                        {prod.offeredByCode && <div className="featured-product-box-plan">
                                                            <div className="offered-by-code-title">{T.translate('renewService.offerCodeFlag')}</div>
                                                        </div>}
                                                        <div className="grey min-h-price">
                                                            {prod.kwhAvgPrice.toFixed(2)}¢<span>{T.translate('renewService.perKwh')}</span>
                                                            <p dangerouslySetInnerHTML={this.getObjectToRender(prod.productDisplayShortDescription)} />
                                                        </div>
                                                        {prod.productDisplayFeature1 && <div className="border-none"><i><FontAwesomeIcon icon={faCheck} /></i>{prod.productDisplayFeature1}</div>}
                                                        {prod.productDisplayFeature2 && <div className="border-none"><i><FontAwesomeIcon icon={faCheck} /></i>{prod.productDisplayFeature2}</div>}
                                                        {prod.productDisplayFeature3 && <div className="border-none"><i><FontAwesomeIcon icon={faCheck} /></i>{prod.productDisplayFeature3}</div>}
                                                        {prod.productDisplayFeature4 && <div className="border-none"><i><FontAwesomeIcon icon={faCheck} /></i>{prod.productDisplayFeature4}</div>}
                                                        {prod.productDisplayFeature5 && <div className="border-none"><i><FontAwesomeIcon icon={faCheck} /></i>{prod.productDisplayFeature5}</div>}

                                                        <div className="grey btn-view">
                                                            <span>
                                                                <button type="button" className="btn btn-default" data-product-id={prod.productID} onClick={this.showPlanDetailsModal}>{T.translate('renewService.viewDetails')}</button>
                                                            </span>
                                                            <button className="btn btn-primary" data-product-id={prod.productID} onClick={this.nextRenewalStep}>{T.translate('renewService.enrollNow')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <BasicModal visible={this.state.displayDetailsModal} title={T.translate('renewService.planDetails')} onClick={this.hidePlanDetailsModal} btnText={T.translate('renewService.close')} size="lg">
                                    <div className="info-section">
                                        <div className="row edit-information-box">
                                            <div className="col-6">
                                                <strong>{T.translate('renewService.productPlan')}:</strong>
                                            </div>
                                            <div className="col-6">{this.state.preSelectedProduct.productDisplayName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="table-responsive table-free-night">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>{T.translate('renewService.effectiveRate')}</th>
                                                                <th>{T.translate('renewService.term')}</th>
                                                                <th>{T.translate('renewService.earlyTerminationFee')}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{this.state.preSelectedProduct.kwhAvgPrice.toFixed(2)}¢<span>{T.translate('renewService.perKwh')}</span></td>
                                                                <td><strong>{this.state.preSelectedProduct.contractLengthName && this.state.preSelectedProduct.contractPeriodName && (this.state.preSelectedProduct.contractLengthName + ' ' + this.state.preSelectedProduct.contractPeriodName)}</strong></td>
                                                                <td><small>$</small><strong>{this.state.preSelectedProduct.earlyTerminationFee}</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <p><strong>{T.translate('renewService.consumerInfo')}</strong></p>
                                        <ul className="free-night-ul">
                                            {this.state.preSelectedProduct.docTypes.map((docTypeID, i) => (
                                                <li key={i}>{T.translate('renewService.docType' + docTypeID)}:
                                                    {this.state.preSelectedProduct.productDocuments.filter(x => x.documentTypeID === docTypeID && this.isValidDocumentTdu(x)).map((doc, ixd) => (
                                                        <React.Fragment key={ixd}>
                                                            {' '} {<a href={doc.documentURL} target="_blank" className="a-underline">{T.translate('common.language' + doc.productDocumentLanguageID)}</a>}
                                                        </React.Fragment>))}

                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </BasicModal>

                            </React.Fragment>
                        }

                        {this.state.renewalStep === 2 &&
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="create-account-form">
                                        <div className="clearfix" />
                                        <div className="page-sub-title-green">{T.translate('renewService.planInformation')}</div>
                                        <div className="info-section">
                                            <div className="row edit-information-box">
                                                <div className="col-md-3 col-sm-4">
                                                    <strong>{T.translate('renewService.productPlan')}:</strong>
                                                </div>
                                                <div className="col-md-9 col-sm-8">{this.state.product.productDisplayName}</div>
                                            </div>
                                            <div className="row edit-information-box">
                                                <div className="col-md-3 col-sm-4">
                                                    <strong>{T.translate('renewService.serviceStartDate')}:</strong>
                                                </div>
                                                <div className="col-md-9 col-sm-8">{this.state.product.formattedStartDate}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="table-responsive table-free-night">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>{T.translate('renewService.effectiveRate')}</th>
                                                                    <th>{T.translate('renewService.term')}</th>
                                                                    <th>{T.translate('renewService.earlyTerminationFee')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>{this.state.product.kwhAvgPrice.toFixed(2)}¢<span>{T.translate('renewService.perKwh')}</span></td>
                                                                    <td><strong>{this.state.product.contractLengthName && this.state.product.contractPeriodName && (this.state.product.contractLengthName + ' ' + this.state.product.contractPeriodName)}</strong></td>
                                                                    <td><small>$</small><strong>{this.state.product.earlyTerminationFee}</strong></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <p><strong>{T.translate('renewService.consumerInfo')}</strong></p>
                                            <ul className="free-night-ul">
                                                {this.state.product.docTypes.map((docTypeID, i) => (
                                                    <li key={i}>{T.translate('renewService.docType' + docTypeID)}:
                                                        {this.state.product.productDocuments.filter(x => x.documentTypeID === docTypeID && this.isValidDocumentTdu(x)).map((doc, ixd) => (
                                                            <React.Fragment key={ixd}>
                                                                {' '} {<a href={doc.documentURL} target="_blank" className="a-underline">{T.translate('common.language' + doc.productDocumentLanguageID)}</a>}
                                                            </React.Fragment>))}

                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="page-sub-title-green">{T.translate('renewService.confirmationOfOrder')}</div>
                                        <div className="info-section">
                                            <div className="checkbox">
                                                <label>
                                                    <input type="checkbox" id="acceptTOS" name="acceptTOS" onChange={this.onChange} checked={this.state.acceptTOS} />
                                                    {T.translate('renewService.acceptTermsOfService')}<span className="red-clr">*</span>
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 form-group">

                                                    <button type="button" className="btn btn-outline-dark mr-3" onClick={this.prevRenewalStep}>{T.translate('common.back')}</button>

                                                    <LoadingButton
                                                        type="button"
                                                        className="btn btn-primary mr-3"
                                                        disabled={!this.state.acceptTOS}
                                                        loading={'false'}
                                                        onClick={this.renewService}
                                                    >{T.translate('renewService.renewNow')}</LoadingButton>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }


                    </React.Fragment>}

                    {this.props.serviceRenewed && this.state.product && <React.Fragment>
                        <div className="card bl-green mb-4 mb-sm-5 bl-br">
                            <div className="card-body pay-echeck">
                                <img src={eligible} alt="eligible" />
                                <div>
                                    <h6 className="card-title text-success">{T.translate('renewService.thanksForRenewing')}</h6>
                                    <p className="lead mb-4">
                                        <em>
                                            {this.props.renewalOffer.accountServicePoint && this.props.renewalOffer.accountServicePoint.servicePoint &&
                                                T.translate('renewService.renewalComplete', this.props.renewalOffer.accountServicePoint.servicePoint)}
                                        </em>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <NavLink to={'/myaccount/dashboard'} className="btn btn-primary">
                            <span className="header-nav-text">{T.translate('renewService.returnToDashboard')}</span>
                        </NavLink>
                    </React.Fragment>
                    }
                </React.Fragment>
                }

            </div>
        );
    }
}

export default connect(
    state => ({ ...state.account, ...state.user, ...state.common }),
    dispatch => bindActionCreators({ ...UserStore.actionCreators, ...CommonStore.actionCreators, ...AccountStore.actionCreators }, dispatch)
)(RenewService);
