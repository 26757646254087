import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import IdleTimer from 'react-idle-timer';

const logo = require(`../../images/${process.env.REACT_APP_CLIENT}/logo-external.png`);
const logoRetina = require(`../../images/${process.env.REACT_APP_CLIENT}/logo-external-retina.png`);

class LayoutExternal extends Component {

    componentWillMount() {
        this.props.init();
    }

    toggleLang = () => {
        this.props.toggleLang();
    }

    onRefresh = () => {
        window.location.reload();
    }

    render() {
        return (
            <React.Fragment>
                <IdleTimer
                    element={document}
                    onIdle={this.onRefresh}
                    timeout={60000 * 60}
                />
                <section className="my-account-section">
                    <div className="my-account-container">
                        <div className="my-account-header">
                            <div className="logo-my-account" style={{ visibility: this.props.showLogoHeaderLogin ? 'collapse' : 'visible'}}>
                                <img src={logo} alt="logo" srcSet={`${logo} 1x, ${logoRetina} 2x`} />
                            </div>
                            <a href="javascript:;" onClick={this.toggleLang} className="my-account-language">
                                <FontAwesomeIcon icon={faGlobeAmericas} /> <span className="header-nav-text">{this.props.lang === 'en' ? 'ESP' : 'ENG'}</span>
                            </a>
                        </div>
                        {this.props.children}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(
    state => state.common,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(LayoutExternal);
