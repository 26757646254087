import * as CommonState from './Common';
import Moment from 'moment';
import T from 'i18n-react';
import { history } from '../index';

const initialState = {
    serviceLoading: false,
    serviceErrorCode: 0,
    serviceErrorMessage: '',
    distributionPoints: [],
    showDistributionPointsModal: false,
    showTransferServiceConfirmModal: false,
    showTransferServiceSuccessModal: false,
    showTransferServiceCancelModal: false,
    distributionPoint: {},
    accountAddress: {},
    servicePoint: {},
    mvoCreated: false
};

export const actionCreators = {

    setServiceError: (errorMessage) => (dispatch) => {
        dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: errorMessage });
    },


    clearServiceState: () => (dispatch, getState) => {
        dispatch({ type: 'CLEAR_SERVICE_STATE' });
    },

    createMvoServiceOrder: (defaultLocation, serviceMoveOutDate, accountAddress) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        let request = {
            accountID: state.common.account.accountID,
            serviceRequestedDate: CommonState.formatDate(serviceMoveOutDate, 'MM/DD/YYYY'),
            servicePoint: defaultLocation,
            accountAddress,
            ipAddress: state.common.ip,
            source: 'My Account Portal',
            languageID: state.common.langID
        };

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/ProcessExternalServiceOrder`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                var mvoCreated = data.trackingNumber && data.trackingNumber.trim() !== '';
                dispatch({ type: 'RESPONSE_MVO_REQUEST', mvoCreated });
            })
            .catch(function (error) {
                dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_SERVICE_START' });
    },

    createTransferOfService: (view) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        var address = view.billingAddress;
        var dp = state.service.distributionPoint;

        let request = {};
        request.accountID = state.common.accountID;
        request.languageID = state.common.langID;
        request.newMailingAddress = address;
        request.newDistributionPoint = dp;
        request.serviceTransferDate = view.moveOutDate;
        request.moveInDate = view.moveInDate;
        request.accountServicePointID = view.accountServicePointID;
        request.ipAddress = state.common.ip;

        fetch(`${state.common.serviceUrl}/ProcessTransferServiceLocation`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                dispatch({ type: 'SET_TRANSFER_SERVICE_CONFIRM_MODAL_VISIBLE', showTransferServiceConfirmModal: false });
                dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: '' });
                dispatch({ type: 'SET_TRANSFER_SERVICE_SUCCESS_MODAL_VISIBLE', showTransferServiceSuccessModal: true });

            })
            .catch(function (error) {
                dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: error.message });
                dispatch({ type: 'SET_TRANSFER_SERVICE_CONFIRM_MODAL_VISIBLE', showTransferServiceConfirmModal: false });
            });

        dispatch({ type: 'REQUEST_SERVICE_START' });

    },

    createMviServiceOrder: (view) => (dispatch, getState) => {


        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = CommonState.secureStorage.getItem('privateToken');

        var dp = state.service.distributionPoint;

        let request = {};
        request.accountID = state.common.accountID;
        request.languageID = state.common.langID;
        request.newDistributionPoint = dp;
        request.moveInDate = view.moveInDate;
        request.ipAddress = state.common.ip;

        fetch(`${state.common.serviceUrl}/ProcessAddServiceLocation`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (data.isError && data.errorCode === 300) {
                    dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: '', serviceErrorCode: data.errorCode });
                    dispatch({ type: 'SET_ADD_LOCATION_CONFIRM_MODAL_VISIBLE', showTransferServiceConfirmModal: false });
                } else {
                    dispatch({ type: 'SET_ADD_LOCATION_CONFIRM_MODAL_VISIBLE', showTransferServiceConfirmModal: false });
                    dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: '' });
                    dispatch({ type: 'SET_ADD_LOCATION_SUCCESS_MODAL_VISIBLE', showTransferServiceSuccessModal: true });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: error.message });
                dispatch({ type: 'SET_ADD_LOCATION_CONFIRM_MODAL_VISIBLE', showTransferServiceConfirmModal: false });
            });

        dispatch({ type: 'REQUEST_SERVICE_START' });
    },

    getDistributionPoints: (address) => async (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;

        headers.Authorization = state.common.publicToken;

        let request = {};
        request.address = address.address;
        request.apartmentNumber = address.apartmentNumber;
        request.city = address.city;
        request.state = address.state;
        request.zip = address.zip;
        request.esiID = address.esiID;

        let queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

        dispatch({ type: 'REQUEST_SERVICE_START' });

        let distServicePoints = await fetch(`${state.common.serviceUrl}/DistributionPoints?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .catch(function (error) {
                dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: error.message });
            });

        if (!distServicePoints || distServicePoints.length === 0) {
            dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: T.translate('transferService.serviceAddressNotFound') });
            return;
        }
        else if (distServicePoints.length === 1) {
            dispatch({ type: 'SET_DISTRIBUTION_POINT', distributionPoint: distServicePoints[0] });
        }
        else if (distServicePoints.length > 1) {
            dispatch({ type: 'SET_DISTRIBUTION_POINTS', distributionPoints: distServicePoints });
            dispatch({ type: 'SET_DISTRIBUTION_POINT_MODAL_VISIBLE', showDistributionPointsModal: true });
        }
    },

    setDistributionPoint: (dp) => (dispatch) => {
        dispatch({ type: 'SET_DISTRIBUTION_POINT', distributionPoint: dp });
    },

    setServicePoint: (sp) => (dispatch) => {
        dispatch({ type: 'SET_SERVICE_POINT', servicePoint: sp });
    },

    setDistributionPointModalVisible: (visible) => (dispatch) => {
        dispatch({ type: 'SET_DISTRIBUTION_POINT_MODAL_VISIBLE', showDistributionPointsModal: visible });
    },

    setTransferServiceConfirmModalVisible: (visible) => (dispatch) => {
        dispatch({ type: 'SET_TRANSFER_SERVICE_CONFIRM_MODAL_VISIBLE', showTransferServiceConfirmModal: visible });
    },

    setTransferServiceCancelModalVisible: (visible) => (dispatch) => {
        dispatch({ type: 'SET_TRANSFER_SERVICE_CANCEL_MODAL_VISIBLE', showTransferServiceCancelModal: visible });
    },

    setTransferServiceSuccessModalVisible: (visible) => (dispatch) => {
        dispatch({ type: 'SET_TRANSFER_SERVICE_SUCCESS_MODAL_VISIBLE', showTransferServiceSuccessModal: visible });
    },

    setNewServiceLocationConfirmModalVisible: (visible) => (dispatch) => {
        dispatch({ type: 'SET_ADD_LOCATION_CONFIRM_MODAL_VISIBLE', showTransferServiceConfirmModal: visible });
    },

    setNewServiceLocationCancelModalVisible: (visible) => (dispatch) => {
        dispatch({ type: 'SET_ADD_LOCATION_CANCEL_MODAL_VISIBLE', showTransferServiceCancelModal: visible });
    },

    setNewServiceLocationSuccessModalVisible: (visible) => (dispatch) => {
        dispatch({ type: 'SET_ADD_LOCATION_SUCCESS_MODAL_VISIBLE', showTransferServiceSuccessModal: visible });
    },

    showServiceError: (message, code = 0) => (dispatch) => {
        dispatch({ type: 'SET_SERVICE_ERROR', serviceErrorMessage: message, serviceErrorCode: code });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case 'REQUEST_SERVICE_START':
            return { ...state, serviceLoading: true, serviceErrorMessage: '' };
        case 'SET_SERVICE_ERROR':
            return { ...state, serviceLoading: false, serviceErrorMessage: action.serviceErrorMessage, serviceErrorCode: action.serviceErrorCode ? action.serviceErrorCode : 0 };
        case 'SET_DISTRIBUTION_POINTS':
            return { ...state, serviceLoading: false, serviceErrorMessage: '', distributionPoints: action.distributionPoints };
        case 'SET_DISTRIBUTION_POINT':
            return { ...state, serviceLoading: false, serviceErrorMessage: '', distributionPoint: action.distributionPoint };
        case 'SET_SERVICE_POINT':
            return { ...state, servicePoint: action.servicePoint };
        case 'SET_DISTRIBUTION_POINT_MODAL_VISIBLE':
            return { ...state, showDistributionPointsModal: action.showDistributionPointsModal };
        case 'SET_TRANSFER_SERVICE_CONFIRM_MODAL_VISIBLE':
            return { ...state, showTransferServiceConfirmModal: action.showTransferServiceConfirmModal };
        case 'SET_TRANSFER_SERVICE_CANCEL_MODAL_VISIBLE':
            return { ...state, showTransferServiceCancelModal: action.showTransferServiceCancelModal };
        case 'SET_TRANSFER_SERVICE_SUCCESS_MODAL_VISIBLE':
            return { ...state, showTransferServiceSuccessModal: action.showTransferServiceSuccessModal };
        case 'SET_ADD_LOCATION_CONFIRM_MODAL_VISIBLE':
            return { ...state, showTransferServiceConfirmModal: action.showTransferServiceConfirmModal };
        case 'SET_ADD_LOCATION_CANCEL_MODAL_VISIBLE':
            return { ...state, showTransferServiceCancelModal: action.showTransferServiceCancelModal };
        case 'SET_ADD_LOCATION_SUCCESS_MODAL_VISIBLE':
            return { ...state, showTransferServiceSuccessModal: action.showTransferServiceSuccessModal };
        case 'RESPONSE_MVO_REQUEST':
            return { ...state, serviceLoading: false, serviceErrorMessage: action.serviceErrorMessage, mvoCreated: action.mvoCreated };
        case 'CLEAR_SERVICE_STATE':
            return { ...state, serviceLoading: false, serviceErrorMessage: '', serviceErrorCode: 0, mvoCreated: action.mvoCreated, distributionPoint: {} };
        default:
            return state;
    }
};
