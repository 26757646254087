import T from 'i18n-react';
import { history } from '../index';
import * as CommonState from './Common';
import Cookies from 'universal-cookie';

const initialState = {
    quickPayModalVisible: false,
    quickPayErrorMessage: '',
    quickPayAccount: {},
    quickPayAccountFound: false,
    quickPaymentSuccess: false,
    quickPayConfirmationNumber: '',
    userLoading: false,
    userErrorMessage: '',
    userSourceError: '',
    forgotPasswordConfirmation: false,
    forgotUsernameConfirmation: false,
    registerPerson: {},
    registerConfirmation: false,
    verificationCodeConfirmation: false,
    accountVerificationConfirmation: false,
    verifiedEnrollment: false,
    usernameUpdateConfirmation: false,
    confirmRememberMe: false,
    resetPwdInfo: { resetToken: '', resetTokenSuccess: false }
};

export const actionCreators = {

    forgotPassword: (request) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/ResetPassword`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                if (!data.isError) {
                    dispatch({ type: 'SET_FORGOT_PASSWORD_CONFIRMATION', forgotPasswordConfirmation: true });
                } else {
                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: T.translate('forgot.cannotResetPassword') });
                }
            })
            .catch(function (error) {
                if (error.message === 'Account user not found') {
                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: T.translate('forgot.cannotFindUser') });
                }
                else {
                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
                }
            });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    validatePasswordReset: (data) => (dispatch, getState) => {
        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;
        data.languageID = state.common.langID;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/PasswordResetToken`, { headers: headers, method: 'POST', body: JSON.stringify(data), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(resp => {

                if (resp.token) {
                    dispatch({ type: 'SET_VALID_PASSWORD_RESET_TOKEN', resetPwdInfo: { resetToken: resp.token } });
                } else {
                    var errorMessage = resp.isError ? resp.errorMessage : T.translate('accountResetPassword.invalidToken');
                    var userSourceError = resp.isExpiredToken ? 'ExpiredToken' : '';

                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: errorMessage, userSourceError: userSourceError });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_USER_START' });
    },

    updatePasswordWithToken: (data) => (dispatch, getState) => {
        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        let request = {
            password: data.fields['new-password'].value,
            token: data.token
        };

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/PasswordWithToken`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(resp => {

                if (resp.personID) {
                    dispatch({ type: 'SET_AUTOLOGIN_TOKEN', resetPwdInfo: { resetToken: '', resetTokenSuccess: true } });
                } else {
                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: T.translate('login.invalidUsernamePassword') });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    newPasswordResetLink: (data) => (dispatch, getState) => {
        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;
        data.languageID = state.common.langID;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/NewPasswordResetToken`, { headers: headers, method: 'POST', body: JSON.stringify(data), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(resp => {
            
                if (!resp.isError) {
                    dispatch({ type: 'SET_FORGOT_PASSWORD_CONFIRMATION', forgotPasswordConfirmation: true });
                } else {
                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: data.errorMessage });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_USER_START' });
    },

    sendVerificationCode: (user) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        let req = {};
        req.userName = user.username;
        req.password = user.password;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/VerificationCode`, { headers: headers, method: 'POST', body: JSON.stringify(req), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                dispatch({ type: 'SET_VERIFICATION_CODE_CONFIRMATION', verificationCodeConfirmation: true });
            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    updateUserName: (view) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        let persons = [];

        if (state.account.accountPersons && state.account.accountPersons.length > 0) {
            state.account.accountPersons.forEach(function (a) {
                persons.push(a.persons[0]);
            });
        }

        let request = {};
        request.persons = persons;
        request.password = state.account.password;
        request.userName = view.useEmailAddress ? view.userNameEmailAddress : view.userNameMobilePhone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');

        fetch(`${state.common.serviceUrl}/UserName`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                dispatch({ type: 'REQUEST_USER_END' });
                history.push('/');

            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'SET_USERNAME_UPDATE_CONFIRMATION', usernameUpdateConfirmation: false, confirmRememberMe: false });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    validateVerificationCode: (code) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        let request = {};
        request["token"] = code;
        request["languageID"] = state.common.langID;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/ValidateVerificationCode`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (!data.isError) {
                    dispatch({ type: 'SET_ACCOUNT_VERIFICATION_CONFIRMATION', accountVerificationConfirmation: true });
                } else {
                    var userSourceError = data.isExpiredToken ? 'ExpiredToken' : '';

                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: data.errorMessage, userSourceError: userSourceError });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    regenerateVerificationCode: (code) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        let request = {};
        request["token"] = code;
        request["languageID"] = state.common.langID;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/NewVerificationCode`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (!data.isError) {
                    dispatch({ type: 'SET_VERIFICATION_CODE_CONFIRMATION', verificationCodeConfirmation: true });
                } else {
                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: data.errorMessage });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    validateEnrollmentCode: (code) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/ValidateEnrollmentCode`, { headers: headers, method: 'POST', body: JSON.stringify(code), credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (!data.isError) {
                    dispatch({ type: 'SET_ENROLLMENT_VERIFICATION', verifiedEnrollment: data.isValid });
                }
                else {
                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: data.errorMessage });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    getRegister: (view) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        let request = {};
        request.accountNumber = view.accountNumber;
        request.zipCode = view.zipCode;
        request.email = view.email;
        request.phoneNumber = view.phone ? view.phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '') : '';

        var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/Register?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {
                if (data.person) {
                    dispatch({ type: 'SET_REGISTER_USER_FOUND', registerUserFound: true, registerPerson: data.person });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'SET_REGISTER_USER_FOUND', registerUserFound: false, registerPerson: {} });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    register: (request) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        request.userName = request.useEmailAddress ? request.userNameEmailAddress : request.userNameMobilePhone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
        request.personID = state.user.registerPerson.personID;
        request.phoneNumber = request.phone ? request.phone.replace(' ', '').replace('(', '').replace(')', '').replace('-', '') : '';

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/Register`, { headers: headers, method: 'POST', body: JSON.stringify(request), credentials: "same-origin"})
            .then(CommonState.handleResponse)
            .then(data => {
                if (data.isValidRegistration) {
                    dispatch({ type: 'SET_REGISTER_CONFIRMATION', registerConfirmation: true });
                } else {
                    dispatch({ type: 'SET_USER_ERROR', userErrorMessage: T.translate('register.noAccountFound') });
                }
            })
            .catch(function (error) {
                dispatch({ type: 'SET_USER_ERROR', userErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    getAccount: (request) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        request.companyID = state.common.companyID;

        dispatch({ type: 'SET_QUICK_PAY_ACCOUNT', quickPayAccount: {}, quickPayAccountFound: false });

        var queryString = Object.keys(request).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(request[key])).join('&');

        //TODO: review webmethod
        fetch(`${state.common.serviceUrl}/AccountByZipCode?${queryString}`, { headers: headers, method: 'GET', credentials: "same-origin" })
            .then(CommonState.handleResponse)
            .then(data => {

                if (data.account) {

                    if (!data.account.isCashOnly) {
                        dispatch({ type: 'SET_QUICK_PAY_ACCOUNT', quickPayAccount: data.account, quickPayAccountFound: true });
                    } else {
                        dispatch({ type: 'SET_QUICK_PAY_ERROR', quickPayErrorMessage: T.translate('quickPay.accountCashOnlyMessage') });
                    }

                } else {
                    dispatch({ type: 'SET_QUICK_PAY_ERROR', quickPayErrorMessage: T.translate('quickPay.noAccountFound') });
                }

            })
            .catch(function (error) {
                dispatch({ type: 'SET_QUICK_PAY_ERROR', quickPayErrorMessage: error.message });
            });

        dispatch({ type: 'REQUEST_USER_START' });

    },

    postAccountQuickPay: (request) => (dispatch, getState) => {

        const state = getState();
        let headers = CommonState.headers;
        headers.Authorization = state.common.publicToken;

        request.ipAddress = state.common.ip;
        request.accountID = state.user.quickPayAccount.accountID;

        if (request.tenderTypeID === '1') {
            request.cardNumber = request.cardNumberValue.replace(/-/g, '');

            if (request.expiration.split('/').length === 2) {
                request.expireMonth = request.expiration.split('/')[0];
                request.expireYear = request.expiration.split('/')[1];
            }

        } else if (request.tenderTypeID === '2') {
            request.accountNumber = request.bankAccountNumber;

            if (state.common.paymentStripe) {
                request.paymentToken = request.public_token + '|' + request.account_id;
            } else if (state.common.systemProperties.isSlimCDEnabled && request.public_token) {
                request.paymentToken = request.public_token;
                request.cardNumber = "";
                request.ccv = "";
            }
        }

        if (request.expiration.split('/').length === 2) {
            request.expireMonth = request.expiration.split('/')[0];
            request.expireYear = request.expiration.split('/')[1];
        }

        if (request.amount) {
            request.paymentAmount = request.amount;
        } else {
            if (state.user.quickPayAccount.accountBillingTypeID === 1) {
                request.paymentAmount = state.user.quickPayAccount.suggestedPayment;
            } else {
                request.paymentAmount = state.user.quickPayAccount.totalAmountDue;
            }
        }

        //if i4go payment enabled and cc payment and we got access block try to get unique id
        if (state.common.enableI4GoPayment && state.payment.i4GoAuthorizeClient.i4go_accessblock && request.tenderTypeID === '1') {

            let cardTypeID = CommonState.getCardType(request.cardNumber);

            let requestI4Go = CommonState.getI4GoCardEntryRequest(state.payment.i4GoAuthorizeClient.i4go_accessblock, cardTypeID,
                request.cardNumber, request.expireMonth, request.expireYear, request.ccv);

            var queryString = Object.keys(requestI4Go).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(requestI4Go[key])).join('&');

            fetch(`${state.payment.i4GoAuthorizeClient.i4go_server}/index.cfm?${queryString}`, { method: 'GET' })
                .then(CommonState.handleResponse)
                .then(data => {

                    if (data.i4go_responsecode === '1' && data.i4go_uniqueid) {

                        let req = {};
                        req.paymentAmount = request.paymentAmount;
                        req.paymentToken = data.i4go_uniqueid;
                        req.ipAddress = request.ipAddress;
                        req.accountID = request.accountID;
                        req.cardZipCode = request.cardZipCode;
                        req.nameOnAccount = request.nameOnAccount;
                        req.tenderTypeID = request.tenderTypeID;
                        req.i4GoCardType = CommonState.getI4GoCardType(cardTypeID);

                        fetchExternalAccountPayment(dispatch, getState, req);

                    } else {
                        dispatch({ type: 'SET_QUICK_PAY_ERROR', quickPayErrorMessage: data.i4go_responsetext });
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    dispatch({ type: 'SET_QUICK_PAY_ERROR', quickPayErrorMessage: error.message });
                });

            dispatch({ type: 'REQUEST_USER_START' });

        } else {
            fetchExternalAccountPayment(dispatch, getState, request);
        }

    },

    setQuickPayModalVisible: (quickPayModalVisible) => (dispatch) => {
        dispatch({ type: 'SET_QUICK_PAY_MODAL_VISIBLE', quickPayModalVisible });
    },

    clearUserState: () => (dispatch) => {
        dispatch({ type: 'CLEAR_USER_STATE' });
    },

    setError: (errorMessage) => (dispatch) => {
        dispatch({ type: 'SET_USER_ERROR', userErrorMessage: errorMessage });
    },

    setQuickPayError: (errorMessage) => (dispatch) => {
        dispatch({ type: 'SET_QUICK_PAY_ERROR', quickPayErrorMessage: errorMessage });
    }

};

var fetchExternalAccountPayment = function (dispatch, getState, req) {

    const state = getState();
    let headers = CommonState.headers;
    console.log(req);
    //CV ZD14560 2020/03/26 adding this to exclude the stripe objects from the request body, and prevent the circular reference problem
    var requestBody = JSON.stringify(req, (key, value) => {
        if (key === "stripe" || key === "elements" || key === "cardNumberElement" || key === "cardExpiryElement" || key === "cardCvcElement") return null;
        else return value;
    });

    fetch(`${state.common.serviceUrl}/ExternalAccountPayment`, { headers: headers, method: 'POST', body: requestBody, credentials: "same-origin" })
        .then(CommonState.handleResponse)
        .then(data => {

            if (data.isApproved) {
                dispatch({ type: 'SET_QUICK_PAY_SUCCESS', quickPaymentSuccess: true, quickPayConfirmationNumber: data.paymentConfirmationNumber });
            } else {
                dispatch({ type: 'SET_QUICK_PAY_ERROR', quickPayErrorMessage: data.errorMessage });
            }
        })
        .catch(function (error) {
            dispatch({ type: 'SET_QUICK_PAY_ERROR', quickPayErrorMessage: error.message });
        });

    dispatch({ type: 'REQUEST_USER_START' });

};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case 'REQUEST_USER_START':
            return { ...state, userLoading: true, userErrorMessage: '', quickPayErrorMessage: '' };
        case 'REQUEST_USER_END':
            return { ...state, userLoading: false };
        case 'REQUEST_AUTOLOGIN_END':
            return { ...state, userLoading: false, resetPwdInfo: action.resetPwdInfo };
        case 'SET_USER_ERROR':
            return { ...state, userErrorMessage: action.userErrorMessage, userLoading: false, userSourceError: action.userSourceError };
        case 'SET_QUICK_PAY_ERROR':
            return { ...state, quickPayErrorMessage: action.quickPayErrorMessage, userLoading: false };
        case 'SET_QUICK_PAY_ACCOUNT':
            return { ...state, quickPayAccount: action.quickPayAccount, userLoading: false, quickPayAccountFound: action.quickPayAccountFound };
        case 'SET_QUICK_PAY_SUCCESS':
            return { ...state, userLoading: false, quickPaymentSuccess: action.quickPaymentSuccess, quickPayConfirmationNumber: action.quickPayConfirmationNumber };
        case 'SET_FORGOT_PASSWORD_CONFIRMATION':
            return { ...state, userLoading: false, forgotPasswordConfirmation: action.forgotPasswordConfirmation };
        case 'SET_FORGOT_USERNAME_CONFIRMATION':
            return { ...state, userLoading: false, forgotUsernameConfirmation: action.forgotUsernameConfirmation };
        case 'SET_REGISTER_CONFIRMATION':
            return { ...state, userLoading: false, registerConfirmation: action.registerConfirmation };
        case 'SET_USERNAME_UPDATE_CONFIRMATION':
            return { ...state, userLoading: false, usernameUpdateConfirmation: action.usernameUpdateConfirmation, confirmRememberMe: action.confirmRememberMe };
        case 'SET_REGISTER_USER_FOUND':
            return { ...state, userLoading: false, registerUserFound: action.registerUserFound, registerPerson: action.registerPerson };
        case 'SET_VERIFICATION_CODE_CONFIRMATION':
            return { ...state, userLoading: false, verificationCodeConfirmation: action.verificationCodeConfirmation };
        case 'SET_ACCOUNT_VERIFICATION_CONFIRMATION':
            return { ...state, userLoading: false, accountVerificationConfirmation: action.accountVerificationConfirmation };
        case 'SET_ENROLLMENT_VERIFICATION':
            return { ...state, userLoading: false, verifiedEnrollment: action.verifiedEnrollment };
        case 'SET_VALID_PASSWORD_RESET_TOKEN':
            return { ...state, userLoading: false, resetPwdInfo: action.resetPwdInfo };
        case 'SET_AUTOLOGIN_TOKEN':
            return { ...state, userLoading: false, resetPwdInfo: action.resetPwdInfo };
        case 'SET_QUICK_PAY_MODAL_VISIBLE':
            return { ...state, quickPayModalVisible: action.quickPayModalVisible };
        case 'CLEAR_USER_STATE':
            return initialState;
        default:
            return state;
    }

};
